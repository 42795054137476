import { Col, Row } from "react-bootstrap";
import DoughnutChartTypeTwo from "../../../../../../shared/oversight-core/ui-elements/doughnut-chart-type-two/doughnut-chart-type-two";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import UnplannedLifeLinePowerTimelineContainer from "../unplanned-life-line-power-timeline-container/unplanned-life-line-power-timeline-container";
import styles from "./unplanned-default.module.scss";

interface IProps {
  setSelectedButton: React.Dispatch<React.SetStateAction<number>>;
}

const UnplannedDefault = (props: IProps) => {
  const { setSelectedButton } = props;
  return (
    <>
      <Row className="align-items-center">
        <Col
          xxl={10}
          className={`${styles.cardContainer} bg-white rounded-3 p-3`}
        >
          <Row>
            <Col className="text-light font-weight-500 font-size-16">
              Unplanned Outage Cover
            </Col>
          </Row>
          <Row className="align-items-center mt-3">
            <Col>
              <Row>
                <Col className="col-auto">
                  <DoughnutChartTypeTwo
                    percentage={75}
                    primaryBackgroundColor="#B66E6E"
                    primaryBorderColor="#B66E6E"
                    backgroundColor="#F2EEF9"
                    borderColor="#F2EEF9"
                  />
                </Col>
                <Col>
                  <Row className="align-content-between h-100">
                    <Col xs={12}>
                      <Row>
                        <Col className="text-dark font-weight-600 font-size-32">
                          2.9 units
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-light font-weight-500 font-size-16">
                          Left from 4 Units
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row className="row-cols-auto">
                        <Col>
                          <Row>
                            <Col className="text-light font-weight-400 font-size-10">
                              Package ID
                            </Col>
                          </Row>
                          <Row>
                            <Col className="text-dark font-weight-500 font-size-16">
                              pc13a8
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col className="text-light font-weight-400 font-size-10">
                              Capacity
                            </Col>
                          </Row>
                          <Row>
                            <Col className="text-dark font-weight-500 font-size-16">
                              300 kWh
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col className="text-light font-weight-400 font-size-10">
                              Max Power
                            </Col>
                          </Row>
                          <Row>
                            <Col className="text-dark font-weight-500 font-size-16">
                              1 kW
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col className="text-light font-weight-400 font-size-10">
                              Duration
                            </Col>
                          </Row>
                          <Row>
                            <Col className="text-dark font-weight-500 font-size-16">
                              Nov 25 - Dec 25
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col className="text-light font-weight-400 font-size-10">
                              Supplier
                            </Col>
                          </Row>
                          <Row>
                            <Col className="text-dark font-weight-500 font-size-16">
                              Leco
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col className="text-light font-weight-400 font-size-10">
                              Battery
                            </Col>
                          </Row>
                          <Row>
                            <Col className="text-dark font-weight-500 font-size-16">
                              145-Maharagama
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col className="text-light font-weight-400 font-size-10">
                              Price
                            </Col>
                          </Row>
                          <Row>
                            <Col className="text-dark font-weight-500 font-size-16">
                              1125 LKR
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="col-auto me-4">
              <Row>
                <Col>
                  <MaterialIcon
                    icon="block"
                    color="#CA3E3E"
                    className="cursor-pointer"
                  />
                </Col>
                <Col>
                  <MaterialIcon
                    icon="cached"
                    color="#302B89"
                    className="cursor-pointer"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xxl={2} className="mt-4 mt-xxl-0 px-0 px-xxl-3">
          <div
            className={`${styles.cardContainer} bg-blue-3 rounded-3 py-3 cursor-pointer`}
            onClick={() => {
              setSelectedButton(1);
            }}
          >
            <Row className="align-items-center justify-content-center gap-2">
              <Col className="col-auto pe-0">
                <MaterialIcon icon="contract" color="#fff" />
              </Col>
              <Col className="ps-0 font-weight-500 text-white col-auto">
                Contracts
              </Col>
            </Row>
          </div>
          <Row className="mt-4">
            <Col className="col-6">
              <div
                className={`${styles.otherCardContainer} bg-white rounded-3 py-3 cursor-pointer`}
                onClick={() => {
                  setSelectedButton(2);
                }}
              >
                <Row className="align-items-center justify-content-center gap-2">
                  <Col className="col-auto pe-0">
                    <MaterialIcon icon="bid_landscape" color="#69768B" />
                  </Col>
                  <Col className="col-auto font-weight-500 text-light ps-0">
                    Bid
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="col-6">
              <div
                className={`${styles.otherCardContainer} bg-white rounded-3 py-3 cursor-pointer`}
                onClick={() => {
                  console.log("History");
                }}
              >
                <Row className="align-items-center justify-content-center">
                  <Col className="col-auto font-weight-500 text-light">
                    History
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="text-dark font-weight-500 font-size-16">
          Lifeline Power Timeline
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <UnplannedLifeLinePowerTimelineContainer />
        </Col>
      </Row>
    </>
  );
};

export default UnplannedDefault;
