import IViewUserSolarPlantDailyPowerRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/view-user-solar-plant-daily-power-request-dto";
import IViewUserSolarPlantEnergyRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/view-user-solar-plant-energy-request-dto";
import IViewUserSolarPlantDetailsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-details-response-dto";
import IViewUserSolarPlantEnergyResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-energy-response-dto";
import IViewUserSolarPlantPowerResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-power-response-dto";
import IViewUserSolarPlantInvertersResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plantInverters-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const generationAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    viewUserPlantDetails: builder.query<
      IViewUserSolarPlantDetailsResponseDTO,
      {
        plantId: string;
      }
    >({
      transformErrorResponse,
      query({ plantId }) {
        return {
          url: `energy-generation/generation-service-provider/${plantId}/view`,
          method: "GET",
        };
      },
    }),
    viewUserPlantDailyPower: builder.query<
      IViewUserSolarPlantPowerResponseDTO,
      IViewUserSolarPlantDailyPowerRequestDTO
    >({
      transformErrorResponse,
      query(data: IViewUserSolarPlantDailyPowerRequestDTO) {
        return {
          url: `energy-generation/generation-service-provider/${data.plantId}/power/view`,
          method: "POST",
          body: {
            year: data.year,
            month: data.month,
            day: data.day,
          },
        };
      },
    }),
    viewUserPlantGeneration: builder.query<
      IViewUserSolarPlantEnergyResponseDTO,
      IViewUserSolarPlantEnergyRequestDTO
    >({
      transformErrorResponse,
      query(data: IViewUserSolarPlantEnergyRequestDTO) {
        return {
          url: `energy-generation/generation-service-provider/${data.plantId}/energy-generation/view`,
          method: "POST",
          body: {
            year: data.year,
            month: data.month,
            day: data.day,
            viewType: data.viewType,
          },
        };
      },
    }),
    viewUserPlantInverters: builder.query<
      IViewUserSolarPlantInvertersResponseDTO,
      {
        plantId: string;
        pageNumber?: number;
        pageSize?: number;
        sortBy?: string;
        ascending?: boolean;
      }
    >({
      transformErrorResponse,
      query({ plantId, pageNumber, pageSize, sortBy, ascending }) {
        return {
          url: `energy-generation/generation-service-provider/${plantId}/inverters/view`,
          method: "POST",
          params: {
            pageNumber: pageNumber,
            pageSize: pageSize,
            sortBy: sortBy,
            ascending: ascending,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyViewUserPlantDetailsQuery,
  useLazyViewUserPlantDailyPowerQuery,
  useLazyViewUserPlantGenerationQuery,
  useLazyViewUserPlantInvertersQuery,
} = generationAPI;
