import { Col, Row } from "react-bootstrap";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import MaterialIcon, { MtIcon } from "../material-icon/material-icon";

interface IProps {
  value: number;
  maxValue: number;
  icon: MtIcon;
  circleRatio?: number;
  unit?: string;
  width?: number;
  height?: number;
  trailColor?: string;
  isDifferentStyles?: boolean;
}

const Gauge = (props: IProps) => {
  const {
    icon,
    value,
    maxValue,
    circleRatio = 0.5,
    unit = "kW",
    width = 200,
    height = 100,
    trailColor = "#F7F8EE",
    isDifferentStyles = false,
  } = props;

  const calculatePercentage = (currentValue: number, maxValue: number) => {
    if (maxValue === 0) return 0;
    return (currentValue / maxValue) * 100;
  };

  return (
    <div style={{ width: width, height: height }}>
      <CircularProgressbarWithChildren
        value={calculatePercentage(value, maxValue)}
        styles={buildStyles({
          rotation: 0.75, // Rotate the circle to make it a half-circle
          pathColor: "#D08700", // Color for the gauge
          trailColor: trailColor, // Background trail color
          pathTransitionDuration: 0.5,
        })}
        circleRatio={circleRatio} // Makes it a half-circle
      >
        <Row
          className={`align-items-center justify-content-center ${
            isDifferentStyles ? `flex-column` : `mb-4`
          }`}
        >
          <Col xs={"auto"} className={`${!isDifferentStyles && `pe-0`}`}>
            <MaterialIcon icon={icon} size={25} color="#D08700" />
          </Col>
          {isDifferentStyles ? (
            <>
              <Col
                xs={"auto"}
                className="text-dark font-size-32 font-weight-500"
                style={{ lineHeight: "35px" }}
              >
                {value}
              </Col>
              <Col
                xs={"auto"}
                className="text-light font-size-16 font-weight-400"
                style={{ lineHeight: "12px" }}
              >
                {unit}
              </Col>
            </>
          ) : (
            <Col xs={"auto"} className="ps-1">
              <label className="text-dark font-size-32 font-weight-500">
                {value}
              </label>
              <label className="text-light font-size-16 font-weight-400 ps-1">
                {unit}
              </label>
            </Col>
          )}
        </Row>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default Gauge;
