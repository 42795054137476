import { Col, Row } from "react-bootstrap";
import ArrowButton from "../../../../../shared/oversight-core/ui-elements/arrow-button/arrow-button";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import styles from "./buy-contract-modal.module.scss";

const BuyContractModal = (props: ModelContainerProps) => {
  const { show, onClose, onConfirm } = props;
  return (
    <ModalContainer
      show={show}
      onConfirm={onConfirm}
      hideConfirm
      hideCancel
      hideClose
    >
      <>
        <Row className="align-items-center">
          <Col className="col-auto ps-4">
            <ArrowButton
              onBack={() => {
                onClose && onClose();
              }}
            />
          </Col>
          <Col className="font-size-32 font-weight-600 text-dark-3 text-center">
            Buy Contract
          </Col>
        </Row>
        <div
          className={`${styles.detailsContainer} mt-4 bg-gray-8 p-3 rounded-3`}
        >
          <Row>
            <Col className="font-size-24 font-weight-600 text-dark">
              3 units
            </Col>
          </Row>
          <Row>
            <Col className="text-light font-weight-500">
              Lifeline Power Contract
            </Col>
          </Row>
          <Row className="mt-4 align-items-center">
            <Col>
              <Row>
                <Col className="text-light font-size-10">Period</Col>
              </Row>
              <Row>
                <Col className="font-weight-500 text-dark">10 Days</Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col className="text-light font-size-10">Price</Col>
              </Row>
              <Row>
                <Col className="font-weight-500 text-dark">1200 LKR</Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col className="text-light font-size-10">Supplier</Col>
              </Row>
              <Row>
                <Col className="font-weight-500 text-dark">LECO</Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-2 align-items-center">
            <Col>
              <Row>
                <Col className="text-light font-size-10">Battery</Col>
              </Row>
              <Row>
                <Col className="font-weight-500 text-dark">145-Maharagama</Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col className="text-light font-size-10">Capacity</Col>
              </Row>
              <Row>
                <Col className="font-weight-500 text-dark">300 kWh</Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col className="text-light font-size-10">Max Power</Col>
              </Row>
              <Row>
                <Col className="font-weight-500 text-dark">1 kW</Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row className="mt-4">
          <Col className="text-light text-center">Total</Col>
        </Row>
        <Row>
          <Col className="text-dark-3 font-size-24 font-weight-600 text-center">
            1200 LKR
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <AppButton text="Buy" />
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default BuyContractModal;
