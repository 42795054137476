import { Col, Container, Row } from "react-bootstrap";
import SpinnerModal from "../../ui-elements/spinner/spinner";

interface IMeterInfoValue {
  name: string;
  value: number;
  unit: string;
}

interface IProps {
  title: string;
  isLoading: boolean;
  maxDemand: number;
  phaseAVoltage: number;
  phaseBVoltage: number;
  phaseCVoltage: number;
  phaseACurrent: number;
  phaseBCurrent: number;
  phaseCCurrent: number;
}

const MeterInfoCard = (props: IProps) => {
  const {
    title,
    isLoading,
    maxDemand,
    phaseAVoltage,
    phaseBVoltage,
    phaseCVoltage,
    phaseACurrent,
    phaseBCurrent,
    phaseCCurrent,
  } = props;

  return (
    <Container fluid className="bg-white py-3 rounded-3">
      {!isLoading ? (
        <>
          <Row>
            <Col className="text-dark font-weight-500">{title}</Col>
          </Row>
          <Row className="align-items-center">
            <Col className="font-size-14 font-weight-500 text-light">
              Max Demand
            </Col>
            <Col className="font-size-14 font-weight-600 text-dark">
              {maxDemand} kW
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="font-size-14 font-weight-500 text-light">
              Phase A Voltage
            </Col>
            <Col className="font-size-14 font-weight-600 text-dark">
              {phaseAVoltage} V
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="font-size-14 font-weight-500 text-light">
              Phase B Voltage
            </Col>
            <Col className="font-size-14 font-weight-600 text-dark">
              {phaseBVoltage} V
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="font-size-14 font-weight-500 text-light">
              Phase C Voltage
            </Col>
            <Col className="font-size-14 font-weight-600 text-dark">
              {phaseCVoltage} V
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="font-size-14 font-weight-500 text-light">
              Phase A Current
            </Col>
            <Col className="font-size-14 font-weight-600 text-dark">
              {phaseACurrent} A
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="font-size-14 font-weight-500 text-light">
              Phase B Current
            </Col>
            <Col className="font-size-14 font-weight-600 text-dark">
              {phaseBCurrent} A
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="font-size-14 font-weight-500 text-light">
              Phase C Current
            </Col>
            <Col className="font-size-14 font-weight-600 text-dark">
              {phaseCCurrent} A
            </Col>
          </Row>
        </>
      ) : (
        <SpinnerModal show={isLoading} withOverlay={false} />
      )}
    </Container>
  );
};

export default MeterInfoCard;
