import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import styles from "./planned-bid-contract.module.scss";

const PlannedBidContract = () => {
  return (
    <>
      <Row>
        <Col className="px-5 text-light">23 Mon 12:00 PM - 23 Mon 03:00 PM</Col>
      </Row>
      <Row className="mt-4">
        <Col className="px-5">
          <div className="container-dash bg-white">
            <Row className="align-items-center">
              <Col sm={12} lg={"auto"}>
                <Row className="align-items-center justify-content-center">
                  <Col className="col-auto">
                    <MaterialIcon icon="add_circle" color="#69768B" />
                  </Col>
                  <Col className="font-weight-500 text-light col-auto">
                    Get Planned Outage Cover Package
                  </Col>
                </Row>
              </Col>
              <Col sm={6} className="col-lg-auto mt-4 mt-lg-0">
                <div
                  className={`${styles.cardContainer} bg-blue-3 rounded-3 p-3 cursor-pointer`}
                >
                  <Row className="align-items-center justify-content-center gap-2">
                    <Col className="col-auto pe-0">
                      <MaterialIcon icon="contract" color="#fff" />
                    </Col>
                    <Col className="ps-0 font-weight-500 text-white col-auto">
                      Contracts
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col sm={6} className="col-lg-auto mt-4 mt-lg-0">
                <div
                  className={`${styles.otherCardContainer} bg-white rounded-3 p-3 cursor-pointer`}
                >
                  <Row className="align-items-center justify-content-center gap-2">
                    <Col className="col-auto pe-0">
                      <MaterialIcon icon="bid_landscape" color="#69768B" />
                    </Col>
                    <Col className="col-auto font-weight-500 text-light ps-0">
                      Bid
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PlannedBidContract;
