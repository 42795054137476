import { Col, Row } from "react-bootstrap";
import MaterialIcon, {
  MtIcon,
} from "../../ui-elements/material-icon/material-icon";
import SpinnerModal from "../../ui-elements/spinner/spinner";
import styles from "./solar-info-card.module.scss";

interface IProps {
  title: string;
  icon: MtIcon;
  iconColor: string;
  backgroundColor: string;
  value: string;
  isSwapTitle?: boolean;
  isFetching?: boolean;
}

const SolarInfoCard = (props: IProps) => {
  const {
    title,
    icon,
    iconColor,
    value,
    backgroundColor,
    isSwapTitle = false,
    isFetching,
  } = props;

  return (
    <div className={`${styles.solarInfoCardContainer} p-4`}>
      <Row className="mx-0">
        <Col
          className="col-auto p-3 rounded-2"
          style={{ backgroundColor: `${backgroundColor}` }}
        >
          <MaterialIcon icon={icon} size={25} color={iconColor} />
        </Col>
      </Row>
      {isSwapTitle ? (
        <>
          <Row className="mt-4">
            <Col className="text-light font-weight-500 font-size-16">
              {title}
            </Col>
          </Row>
          <Row>
            <Col className="text-dark font-weight-600 font-size-24">
              {value}
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="mt-4 justify-content-start">
            <Col className="col-auto text-dark font-weight-600 font-size-24">
              {isFetching ? (
                <SpinnerModal show={isFetching} withOverlay={false} />
              ) : (
                <>{value}</>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="text-light font-weight-500 font-size-16">
              {title}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default SolarInfoCard;
