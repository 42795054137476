import ControllerGraphRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/controller-graph-request-dto";
import ControllerStatisticsRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/controller-statistics-request-dto";
import EnergyConsumptionStatisticsByDateRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/energy-consumption-statistics-by-date-request-dto";
import PowerUsageStatisticsByYearMonthRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/power-usage-statistics-by-year-month-request-dto";
import viewDailyExternalConsumptionDataRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/view-daily-external-consumption-data-request-dto";
import { IViewDevicesWithHighestPowerConsumptionRequestDTO } from "../../../shared/oversight-core/dtos/request-dtos/view-devices-with-highest-power-consumption-request-dto";
import viewExternalConsumptionDataPercentagesRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/view-external-consumption-data-percentages-request-dto";
import viewExternalConsumptionDataRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/view-external-consumption-data-request-dto";
import { IViewSmartPlugEnergyConsumptionByDateRequestDTO } from "../../../shared/oversight-core/dtos/request-dtos/view-smart-plug-energy-consumption-by-date-request-dto";
import ControllerGraphResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/controller-graph-response-dto";
import ControllerStatisticsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/controller-statistics-response-dto";
import EnergyConsumptionStatisticsByDateResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/energy-consumption-statistics-by-date-response-dto";
import PowerUsageStatisticsByYearMonthResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/power-usage-statistics-by-year-month-response-dto";
import viewDailyExternalConsumptionDataResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-daily-external-consumption-data-response-dto";
import { IViewDevicesWithHighestPowerConsumptionResponseDTO } from "../../../shared/oversight-core/dtos/response-dtos/view-devices-with-highest-power-consumption-response-dto";
import viewExternalConsumptionDataPercentagesResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-external-consumption-data-percentages-response-dto";
import ViewExternalConsumptionDataResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-external-consumption-data-response-dto";
import ViewPowerUsageStatsBySpaceClusterResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-power-usage-stats-by-space-cluster-response-dto";
import { IViewSmartPlugEnergyConsumptionByDateResponseDTO } from "../../../shared/oversight-core/dtos/response-dtos/view-smart-plug-energy-consumption-by-date-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const usageAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getControllerStatistics: builder.query<
      ControllerStatisticsResponseDTO,
      ControllerStatisticsRequestDTO
    >({
      transformErrorResponse,
      query(data: ControllerStatisticsRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/smart-device/power-usage/statistics/by-date`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            smartPlugId: data.smartPlugId,
            date: data.date,
            requiredDepth: data.requiredDepth,
            deviceType: data.deviceType,
          },
        };
      },
    }),
    getPowerUsageStatisticsByYearMonth: builder.query<
      PowerUsageStatisticsByYearMonthResponseDTO,
      PowerUsageStatisticsByYearMonthRequestDTO
    >({
      transformErrorResponse,
      query(data: PowerUsageStatisticsByYearMonthRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/smart-device/power-usage/stats/by-year-month`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            smartPlugId: data.smartPlugId,
            year: data.year,
            month: data.month,
            requiredDepth: data.requiredDepth,
            deviceType: data.deviceType,
          },
        };
      },
    }),
    getGraph: builder.query<
      ControllerGraphResponseDTO,
      ControllerGraphRequestDTO
    >({
      transformErrorResponse,
      query(data: ControllerGraphRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/smart-device/power-usage/list`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            smartPlugId: data.smartPlugId,
            from: data.from,
            requiredDepth: data.requiredDepth,
            deviceType: data.deviceType,
          },
        };
      },
    }),
    getEnergyConsumptionStatisticsByDate: builder.query<
      EnergyConsumptionStatisticsByDateResponseDTO,
      EnergyConsumptionStatisticsByDateRequestDTO
    >({
      transformErrorResponse,
      query(data: EnergyConsumptionStatisticsByDateRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/smart-device/energy-consumption/statistics/by-date`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            smartPlugId: data.smartPlugId,
            date: data.date,
            requiredDepth: data.requiredDepth,
            deviceType: data.deviceType,
          },
        };
      },
    }),
    viewDevicesWithHighestPowerConsumption: builder.query<
      IViewDevicesWithHighestPowerConsumptionResponseDTO,
      IViewDevicesWithHighestPowerConsumptionRequestDTO
    >({
      transformErrorResponse,
      query(data: IViewDevicesWithHighestPowerConsumptionRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/device/highest-energy-consumption/view`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            requiredDepth: data.requiredDepth,
            limit: data.limit,
            from: data.from,
          },
        };
      },
    }),
    viewPredictedPowerUsageForCurrentDay: builder.query<
      ViewPowerUsageStatsBySpaceClusterResponseDTO,
      { spaceClusterId: string; zoneOffset: string }
    >({
      transformErrorResponse,
      query({ spaceClusterId, zoneOffset }) {
        return {
          url: `space-cluster/${spaceClusterId}/power-usage/prediction/today`,
          method: "GET",
          params: {
            zoneOffset: zoneOffset,
          },
        };
      },
    }),
    viewExternalConsumptionDataPercentages: builder.query<
      viewExternalConsumptionDataPercentagesResponseDTO,
      viewExternalConsumptionDataPercentagesRequestDTO
    >({
      transformErrorResponse,
      query({ accountNumber, year, month }) {
        return {
          url: `external/consumption-data/percentage/by-year-month`,
          method: "GET",
          params: {
            accountNumber,
            year,
            month,
          },
        };
      },
    }),
    viewExternalConsumptionData: builder.query<
      ViewExternalConsumptionDataResponseDTO,
      viewExternalConsumptionDataRequestDTO
    >({
      transformErrorResponse,
      query({ accountNumber, year, month }) {
        return {
          url: `external/consumption-data/by-year-month`,
          method: "GET",
          params: {
            accountNumber,
            year,
            month,
          },
        };
      },
    }),
    viewDailyExternalConsumptionData: builder.query<
      viewDailyExternalConsumptionDataResponseDTO,
      viewDailyExternalConsumptionDataRequestDTO
    >({
      transformErrorResponse,
      query({ accountNumber, year, month }) {
        return {
          url: `external/consumption-data/daily/year-month`,
          method: "GET",
          params: {
            accountNumber,
            year,
            month,
          },
        };
      },
    }),
    viewEnergyConsumptionByDate: builder.query<
      IViewSmartPlugEnergyConsumptionByDateResponseDTO,
      IViewSmartPlugEnergyConsumptionByDateRequestDTO
    >({
      transformErrorResponse,
      query(data: IViewSmartPlugEnergyConsumptionByDateRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/smart-device/energy-consumption/by-date`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            smartPlugId: data.smartPlugId,
            requiredDepth: data.requiredDepth,
            deviceType: data.deviceType,
            from: data.from,
            to: data.to,
            viewType: data.viewType,
            zoneOffset: data.zoneOffset,
          },
        };
      },
    }),
  }),
});

export const {
  useLazyGetControllerStatisticsQuery,
  useLazyGetPowerUsageStatisticsByYearMonthQuery,
  useLazyGetGraphQuery,
  useLazyGetEnergyConsumptionStatisticsByDateQuery,
  useLazyViewDevicesWithHighestPowerConsumptionQuery,
  useLazyViewPredictedPowerUsageForCurrentDayQuery,
  useLazyViewExternalConsumptionDataPercentagesQuery,
  useViewExternalConsumptionDataPercentagesQuery,
  useLazyViewExternalConsumptionDataQuery,
  useLazyViewDailyExternalConsumptionDataQuery,
  useLazyViewEnergyConsumptionByDateQuery,
} = usageAPI;
