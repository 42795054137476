import { Col, Row } from "react-bootstrap";
import AppInput from "../../../../../shared/oversight-core/ui-elements/input/app-input";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

const AuctionContractModal = (props: ModelContainerProps) => {
  const { show, onCancel, onClose, onConfirm } = props;

  return (
    <ModalContainer
      show={show}
      title="Bid Now"
      onCancel={onCancel}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmButtonText="Bid"
    >
      <>
        <div className="bg-gray-6 p-3 rounded-3">
          <Row>
            <Col className="font-size-10 text-light">Current Highest Bid</Col>
          </Row>
          <Row>
            <Col className="text-dark font-weight-500">1200 LKR</Col>
          </Row>
        </div>
        <Row className="mt-4">
          <Col className="text-dark font-weight-600">3 kWh</Col>
        </Row>
        <Row>
          <Col className="text-light font-weight-500">
            Lifeline Power Contract
          </Col>
        </Row>
        <Row className="mt-4 align-items-center">
          <Col>
            <Row>
              <Col className="text-light font-size-10">Package ID</Col>
            </Row>
            <Row>
              <Col className="font-weight-500 text-dark">xl25578</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col className="text-light font-size-10">Period</Col>
            </Row>
            <Row>
              <Col className="font-weight-500 text-dark">10 Days</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col className="text-light font-size-10">Supplier</Col>
            </Row>
            <Row>
              <Col className="font-weight-500 text-dark">LECO</Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2 align-items-center">
          <Col>
            <Row>
              <Col className="text-light font-size-10">Battery</Col>
            </Row>
            <Row>
              <Col className="font-weight-500 text-dark">145-Maharagama</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col className="text-light font-size-10">Capacity</Col>
            </Row>
            <Row>
              <Col className="font-weight-500 text-dark">300 kWh</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col className="text-light font-size-10">Max Power</Col>
            </Row>
            <Row>
              <Col className="font-weight-500 text-dark">1 kW</Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <AppInput
            type="number"
            placeholder=""
            errors={Error}
            label="Total Package Value"
            isRequired={false}
          />
        </Row>
      </>
    </ModalContainer>
  );
};

export default AuctionContractModal;
