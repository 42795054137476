import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface IProps {
  percentage: number;
  primaryBackgroundColor: string;
  backgroundColor: string;
  primaryBorderColor: string;
  borderColor: string;
}

const DoughnutChartTypeTwo = (props: IProps) => {
  const {
    percentage,
    primaryBackgroundColor,
    primaryBorderColor,
    backgroundColor,
    borderColor,
  } = props;

  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: (chart: any) => {
      const { ctx, chartArea } = chart;
      const { width, height } = chartArea;

      ctx.save();
      ctx.restore();

      ctx.font = "bold 22px Poppins";
      ctx.textBaseline = "middle";

      const text = `${percentage}%`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: "65%",
    rotation: 90,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      centerText: true,
    },
  };

  const data = {
    labels: [],
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [primaryBackgroundColor, backgroundColor],
        borderColor: [primaryBorderColor, borderColor],
        borderWidth: 1,
      },
    ],
  };

  ChartJS.register(centerTextPlugin);

  return (
    <Doughnut
      data={data}
      options={options}
      style={{ width: "130px", height: "130px" }}
    />
  );
};

export default DoughnutChartTypeTwo;
