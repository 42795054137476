import { Col, Row } from "react-bootstrap";
import DoughnutChartTypeTwo from "../../ui-elements/doughnut-chart-type-two/doughnut-chart-type-two";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import styles from "./outage-cover.module.scss";

interface IProps {
  title: string;
  type: "Planed" | "Unplanned";
  onClick: () => void;
}

const OutageCover = (props: IProps) => {
  const { title, type, onClick } = props;

  return (
    <div
      className={`${styles.cardContainer} p-3 cursor-pointer`}
      onClick={onClick}
    >
      <Row>
        <Col className="text-light font-weight-500 font-size-16">{title}</Col>
      </Row>
      <Row className="align-items-center mt-3">
        <Col>
          <Row>
            <Col className="col-auto">
              <DoughnutChartTypeTwo
                percentage={75}
                primaryBackgroundColor={
                  type === "Planed" ? `#986993` : `#EC9191`
                }
                primaryBorderColor={type === "Planed" ? `#986993` : `#EC9191`}
                backgroundColor={type === "Planed" ? `#F2EEF9` : `#F9EEEE`}
                borderColor={type === "Planed" ? `#F2EEF9` : `#F9EEEE`}
              />
            </Col>
            <Col>
              <Row className="align-content-between h-100">
                <Col xs={12}>
                  <Row>
                    <Col className="text-dark font-weight-600 font-size-32">
                      2.9 units
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-light font-weight-500 font-size-16">
                      Left from 4 Units
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row className="row-cols-auto">
                    <Col>
                      <Row>
                        <Col className="text-light font-weight-400 font-size-10">
                          Package ID
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-dark font-weight-500 font-size-16">
                          pc13a8
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="text-light font-weight-400 font-size-10">
                          Capacity
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-dark font-weight-500 font-size-16">
                          300 kWh
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="text-light font-weight-400 font-size-10">
                          Max Power
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-dark font-weight-500 font-size-16">
                          1 kW
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="col-auto">
          <MaterialIcon
            icon="expand_more"
            color="#69768B"
            style={{ transform: "rotate(-90deg)" }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OutageCover;
