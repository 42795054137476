import { useState } from "react";
import { Container } from "react-bootstrap";
import AuctionContracts from "../auction-contracts/auction-contracts";
import AvailableContracts from "../available-contracts/available-contracts";
import UnplannedDefault from "./unplanned-default/unplanned-default";

const Unplanned = () => {
  const [selectedButton, setSelectedButton] = useState(0);
  return (
    <Container fluid>
      {selectedButton === 0 ? (
        <UnplannedDefault setSelectedButton={setSelectedButton} />
      ) : selectedButton === 1 ? (
        <AvailableContracts setSelectedButton={setSelectedButton} />
      ) : selectedButton === 2 ? (
        <AuctionContracts setSelectedButton={setSelectedButton} />
      ) : (
        <></>
      )}
    </Container>
  );
};

export default Unplanned;
