import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EDateTypes } from "../../../shared/oversight-core/enums/date-types";
import IGenerationFilters from "../../../shared/oversight-core/interfaces/generation-filters";
import { RootState } from "../../store";

const defaultGenerationFilters: IGenerationFilters = {
  selectedDate: new Date().getTime(),
  selectedTotalGenerationDate: new Date().getTime(),
  selectedDateType: EDateTypes.DAILY,
};

export const generationFilterSlice = createSlice({
  name: "generationFilter",
  initialState: { ...defaultGenerationFilters },
  reducers: {
    setGenerationFilter: (
      state,
      action: PayloadAction<
        Omit<IGenerationFilters, "selectedDate" | "selectedDateType">
      >
    ) => {
      state.selectedTotalGenerationDate =
        action.payload.selectedTotalGenerationDate;
    },
  },
});

export const { setGenerationFilter } = generationFilterSlice.actions;

export const selectGenerationFilter = (state: RootState) =>
  state.generationFilter;

export default generationFilterSlice.reducer;
