import { Col, Row } from "react-bootstrap";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
  controllerName: string;
  unlinkPowerConsumerName: string;
  linkPowerConsumerName: string;
  isLoading: boolean;
}

const DeviceUnlinkConfirmationModal = (props: IProps) => {
  const {
    show,
    controllerName,
    unlinkPowerConsumerName,
    linkPowerConsumerName,
    isLoading,
    ...rest
  } = props;

  return (
    <ModalContainer
      title={"Unlink Device"}
      {...rest}
      show={show}
      size="modal-md"
      confirmButtonText="Unlink"
      confirmButtonVariant="red"
      cancelButtonText="Cancel"
      isLoading={isLoading}
    >
      <>
        <Row>
          <Col className="font-weight-400 font-size-14 text-light">
            Are you sure you want to unlink the{" "}
            <span className="font-weight-500">{controllerName}</span> from{" "}
            <span className="font-weight-500">{unlinkPowerConsumerName}</span>{" "}
            and link to{" "}
            <span className="font-weight-500">{linkPowerConsumerName}</span> ?
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default DeviceUnlinkConfirmationModal;
