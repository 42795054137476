import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import BuyContractModal from "../buy-contract-modal/buy-contract-modal";

const AvailableContract = () => {
  const [isShowBuyContractModal, setIsShowBuyContractModal] = useState(false);

  return (
    <>
      <div className="bg-white rounded-4 p-3">
        <Row className="align-items-center">
          <Col xl={10}>
            <Row className="align-items-start">
              <Col xl={2}>
                <Row>
                  <Col className="text-dark font-weight-600">3 Units</Col>
                </Row>
                <Row>
                  <Col className="text-light font-weight-500">
                    Unplanned Outage Cover
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="mt-4 mt-xl-0">
                    <Row>
                      <Col className="font-size-10 text-light">Package ID</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">xl25578</Col>
                    </Row>
                  </Col>
                  <Col className="mt-4 mt-xl-0">
                    <Row>
                      <Col className="font-size-10 text-light">Period</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">10 Days</Col>
                    </Row>
                  </Col>
                  <Col className="mt-4 mt-xl-0">
                    <Row>
                      <Col className="font-size-10 text-light">Supplier</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">Leco</Col>
                    </Row>
                  </Col>
                  <Col className="mt-4 mt-xl-0">
                    <Row>
                      <Col className="font-size-10 text-light">Battery</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">
                        145-Maharagama
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mt-4 mt-xl-0">
                    <Row>
                      <Col className="font-size-10 text-light">Capacity</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">300 kWh</Col>
                    </Row>
                  </Col>
                  <Col className="mt-4 mt-xl-0">
                    <Row>
                      <Col className="font-size-10 text-light">Max Power</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">1 kW</Col>
                    </Row>
                  </Col>
                  <Col className="mt-4 mt-xl-0">
                    <Row>
                      <Col className="font-size-10 text-light">Price</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">1200 LKR</Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xl={2} className="mt-4 mt-xl-0">
            <AppButton
              text="Buy"
              onClick={() => {
                setIsShowBuyContractModal(true);
              }}
            />
          </Col>
        </Row>
      </div>
      <BuyContractModal
        show={isShowBuyContractModal}
        onCancel={() => {
          setIsShowBuyContractModal(false);
        }}
        onClose={() => {
          setIsShowBuyContractModal(false);
        }}
        onConfirm={() => {}}
      />
    </>
  );
};

export default AvailableContract;
