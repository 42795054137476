export enum EPrivileges {
  GENERAL_WRITE_PRIVILEGE = "GENERAL_WRITE_PRIVILEGE",
  GENERAL_READ_PRIVILEGE = "GENERAL_READ_PRIVILEGE",
  DASHBOARD_READ_PRIVILEGE = "DASHBOARD_READ_PRIVILEGE",
  DASHBOARD_WRITE_PRIVILEGE = "DASHBOARD_WRITE_PRIVILEGE",
  DEVICE_READ_PRIVILEGE = "DEVICE_READ_PRIVILEGE",
  DEVICE_WRITE_PRIVILEGE = "DEVICE_WRITE_PRIVILEGE",
  CONTROLLER_READ_PRIVILEGE = "CONTROLLER_READ_PRIVILEGE",
  CONTROLLER_WRITE_PRIVILEGE = "CONTROLLER_WRITE_PRIVILEGE",
  SCHEDULE_READ_PRIVILEGE = "SCHEDULE_READ_PRIVILEGE",
  SCHEDULE_WRITE_PRIVILEGE = "SCHEDULE_WRITE_PRIVILEGE",
  MONITORING_READ_PRIVILEGE = "MONITORING_READ_PRIVILEGE",
  MONITORING_WRITE_PRIVILEGE = "MONITORING_WRITE_PRIVILEGE",
  GENERATION_READ_PRIVILEGE = "GENERATION_READ_PRIVILEGE",
  GENERATION_WRITE_PRIVILEGE = "GENERATION_WRITE_PRIVILEGE",
  USAGE_GUIDE_READ_PRIVILEGE = "USAGE_GUIDE_READ_PRIVILEGE",
  USAGE_GUIDE_WRITE_PRIVILEGE = "USAGE_GUIDE_WRITE_PRIVILEGE",
  USER_MANAGEMENT_READ_PRIVILEGE = "USER_MANAGEMENT_READ_PRIVILEGE",
  USER_MANAGEMENT_WRITE_PRIVILEGE = "USER_MANAGEMENT_WRITE_PRIVILEGE",
  EXTERNAL_GENERATION_READ_PRIVILEGE = "EXTERNAL_GENERATION_READ_PRIVILEGE",
  EXTERNAL_GENERATION_WRITE_PRIVILEGE = "EXTERNAL_GENERATION_WRITE_PRIVILEGE",
  ONLY_EXTERNAL_GENERATION_WRITE_PRIVILEGE = "ONLY_EXTERNAL_GENERATION_WRITE_PRIVILEGE",
  ONLY_EXTERNAL_GENERATION_READ_PRIVILEGE = "ONLY_EXTERNAL_GENERATION_READ_PRIVILEGE",
  ROLE_ADMIN = "ROLE_ADMIN",
}
