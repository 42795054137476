import { Col, Container, Row } from "react-bootstrap";
import MaterialIcon, {
  MtIcon,
} from "../../ui-elements/material-icon/material-icon";
import SpinnerModal from "../../ui-elements/spinner/spinner";

interface IProps {
  title: string;
  unit: string;
  icon: MtIcon;
  iconColor: string;
  iconBackgroundColor: string;
  value: number;
  valueText: string;
  day: number;
  peak: number;
  offPeak: number;
  isLoading: boolean;
}

const ImportExportInfoCard = (props: IProps) => {
  const {
    title,
    unit,
    value,
    valueText,
    day,
    peak,
    offPeak,
    icon,
    iconColor,
    iconBackgroundColor,
    isLoading,
  } = props;

  return (
    <Container fluid className="bg-white rounded-3 py-3">
      {!isLoading ? (
        <>
          <Row className="align-items-center">
            <Col className="col-auto">
              <MaterialIcon
                style={{ backgroundColor: iconBackgroundColor }}
                icon={icon}
                color={iconColor}
                className="p-3 rounded"
              />
            </Col>
            <Col className="text-light font-weight-500 col-auto">{title}</Col>
            <Col className="text-light font-weight-500 col-auto px-0">{`(${unit})`}</Col>
          </Row>
          <Row className="mt-2">
            <Col className="font-weight-600 font-size-24 text-dark">
              {value.toFixed(2)}
            </Col>
          </Row>
          <Row>
            <Col className="font-weight-500 font-size-14 text-light">
              {valueText}
            </Col>
          </Row>
          <Row className="mt-4 align-items-center">
            <Col className="col-auto">
              <Row>
                <Col className="text-dark font-weight-500">
                  {day.toFixed(2)}
                </Col>
              </Row>
              <Row>
                <Col className="text-dark font-weight-500 font-size-14">
                  Day
                </Col>
              </Row>
            </Col>
            <Col className="col-auto">
              <Row>
                <Col className="text-dark font-weight-500">
                  {peak.toFixed(2)}
                </Col>
              </Row>
              <Row>
                <Col className="text-dark font-weight-500 font-size-14">
                  Peak
                </Col>
              </Row>
            </Col>
            <Col className="col-auto">
              <Row>
                <Col className="text-dark font-weight-500">
                  {offPeak.toFixed(2)}
                </Col>
              </Row>
              <Row>
                <Col className="text-dark font-weight-500 font-size-14">
                  Off Peak
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <SpinnerModal show={isLoading} withOverlay={false} />
        </>
      )}
    </Container>
  );
};

export default ImportExportInfoCard;
