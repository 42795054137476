import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import AppDatePicker from "../../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import PlannedLifeLinePowerTimelineContainer from "./planned-life-line-power-timeline-container/planned-life-line-power-timeline-container";

const Planned = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  return (
    <>
      <Row>
        <Col sm={5} lg={4} xl={3}>
          <AppDatePicker
            selectedDate={selectedDate}
            onChange={(date) => {
              setSelectedDate(date);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <PlannedLifeLinePowerTimelineContainer />
        </Col>
      </Row>
    </>
  );
};

export default Planned;
