import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { IForecast } from "../../interfaces/forecast";
import { ITodayWeather } from "../../interfaces/today-weather";
import { IWeatherData } from "../../interfaces/weather-data";
import MaterialIcon, {
  MtIcon,
} from "../../ui-elements/material-icon/material-icon";
import SpinnerModal from "../../ui-elements/spinner/spinner";
import styles from "./wether-info.module.scss";

interface IProps {
  longitude: number;
  latitude: number;
  address: string;
  isFetching: boolean;
}

const WetherInfo = (props: IProps) => {
  const { longitude, latitude, address, isFetching } = props;

  const WHETHER_API_KEY = "9cbad2a4ef09ebd9d2885eb5e97e8c7f";

  const [forecast, setForecast] = useState<IForecast[]>([]);
  const [todayWeather, setTodayWeather] = useState<ITodayWeather>();

  useEffect(() => {
    const fetchWeatherData = async () => {
      await fetch(
        `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&units=metric&appid=${WHETHER_API_KEY}`
      )
        .then((response) => response.json())
        .then((data: IWeatherData) => {
          processWeatherData(data);
        })
        .catch(() => {
          console.error("Error fetching weather data");
        });
    };

    fetchWeatherData();
  }, [latitude, longitude]);

  const processWeatherData = (data: IWeatherData) => {
    const forecastData: IForecast[] = [];
    const today = new Date().getDate();

    const todayData = data.list[0];
    setTodayWeather({
      temp: Math.round(todayData.main.temp),
      icon: getMaterialIcon(todayData.weather[0].description),
      description: todayData.weather[0].description,
    });

    // Process the rest of the days for forecast (skip today)
    data.list.forEach((item) => {
      const date = new Date(item.dt_txt);
      const hour = date.getHours();

      if (hour === 12 && date.getDate() !== today) {
        forecastData.push({
          day: date.toLocaleDateString("en-US", { weekday: "long" }),
          minTemp: Math.round(item.main.temp_min),
          maxTemp: Math.round(item.main.temp_max),
          icon: getMaterialIcon(item.weather[0].description),
          description: item.weather[0].description,
        });
      }
    });

    setForecast(forecastData.slice(0, 4)); // Get the next 4 days' forecast
  };

  const getMaterialIcon = (description: string) => {
    if (description.includes("clear")) return "clear_day";
    if (description.includes("clouds") || description.includes("cloudy"))
      return "partly_cloudy_day";
    if (description.includes("rain")) return "rainy";
    return "cloud";
  };

  return (
    <div className={`${styles.infoCardContainer} p-4`}>
      <Row className="align-content-between h-100">
        {isFetching ? (
          <SpinnerModal show={isFetching} withOverlay={false} />
        ) : (
          <>
            <Col className="col-12">
              <Row>
                <Col className="text-dark font-weight-500 font-size-16">
                  {address}
                </Col>
              </Row>
              {todayWeather && (
                <Row
                  className={`${styles.todayBackground} align-items-center mx-0 p-3 my-4`}
                >
                  <Col>
                    <Row className="align-items-center">
                      <Col className="col-auto pe-0">
                        <MaterialIcon
                          icon={todayWeather.icon as MtIcon}
                          color="#D08700"
                        />
                      </Col>
                      <Col className="text-dark font-weight-500 font-size-16">
                        Today
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-auto text-dark font-weight-500 font-size-24">
                    {todayWeather.temp}&deg;
                  </Col>
                </Row>
              )}
              {forecast.map((dayInfo, index) => (
                <Row key={index} className="align-items-center mx-0 p-3">
                  <Col>
                    <Row className="align-items-center">
                      <Col className="col-auto pe-0">
                        <MaterialIcon
                          icon={dayInfo.icon as MtIcon}
                          color="#D08700"
                        />
                      </Col>
                      <Col className="text-dark font-weight-400 font-size-16">
                        {dayInfo.day}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-auto text-light font-weight-400 font-size-14">
                    {dayInfo.minTemp}/{dayInfo.maxTemp}&deg;
                  </Col>
                </Row>
              ))}
            </Col>
            <Col className="col-12 mt-4 mt-xl-0">
              <Row>
                <Col className="text-light font-weight-400 font-size-12">
                  Generator Power Energy (Next Hour):
                </Col>
              </Row>
              <Row>
                <Col className="text-dark font-weight-500 font-size-20">
                  4.2 kWh
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default WetherInfo;
