import moment from "moment";
import { EDateTypes } from "../enums/date-types";

export const formatDate = (selectDate: Date) => {
  return moment(selectDate).toISOString(true);
};

export const getMonthRangeToDisplay = (weekRange: Date[]) => {
  const startingMonthAndYear = {
    month: weekRange[0].getMonth(),
    year: weekRange[0].getFullYear(),
  };
  const endingMonthAndYear = {
    month: weekRange[6].getMonth(),
    year: weekRange[6].getFullYear(),
  };

  const momentStartOfTheWeek = moment(weekRange[0]);
  const momentEndOfTheWeek = moment(weekRange[6]);

  if (startingMonthAndYear.month === endingMonthAndYear.month) {
    return `${momentStartOfTheWeek.format("MMM - YYYY")}`;
  } else if (startingMonthAndYear.year !== endingMonthAndYear.year) {
    return `${momentStartOfTheWeek.format(
      "MMM - YYYY"
    )} - ${momentEndOfTheWeek.format("MMM - YYYY")}`;
  } else {
    return `${momentStartOfTheWeek.format("MMM")} - ${momentEndOfTheWeek.format(
      "MMM"
    )} ${momentStartOfTheWeek.format("YYYY")}`;
  }
};

export const getWeekRange = (date: Date, selectedDate: number): Date[] => {
  const dayOfWeek = date.getDay();

  let difference = dayOfWeek - 1;

  if (difference < 0) {
    difference = 6;
  }

  const dates: Date[] = [];
  const startOfWeek = new Date(selectedDate);
  startOfWeek.setDate(date.getDate() - difference);

  for (let i = 0; i < 7; i++) {
    const newDate = new Date(startOfWeek);
    dates.push(new Date(newDate.setDate(newDate.getDate() + i)));
  }

  return dates;
};

export const getFromDate = (selectedDate: Date, selectedDateType: string) => {
  switch (selectedDateType) {
    case EDateTypes.HOURLY:
      return moment(selectedDate)
        .startOf("day")
        .add(5, "hours")
        .add(30, "minutes")
        .toISOString();
    case EDateTypes.DAILY:
      return moment(selectedDate)
        .startOf("month")
        .add(5, "hours")
        .add(30, "minutes")
        .toISOString();
    case EDateTypes.MONTHLY:
      return moment(selectedDate)
        .startOf("year")
        .add(5, "hours")
        .add(30, "minutes")
        .toISOString();
    default:
      return moment()
        .subtract(10, "years")
        .startOf("year")
        .add(5, "hours")
        .add(30, "minutes")
        .toISOString();
  }
};

export const getToDate = (selectedDate: Date, selectedDateType: string) => {
  const isCurrentMonth = moment(selectedDate).isSame(moment(), "month");
  const isCurrentYear = moment(selectedDate).isSame(moment(), "year");

  switch (selectedDateType) {
    case EDateTypes.HOURLY:
      return moment(selectedDate)
        .add(5, "hours")
        .add(30, "minutes")
        .toISOString();
    case EDateTypes.DAILY:
      return isCurrentMonth
        ? moment().add(5, "hours").add(30, "minutes").toISOString()
        : moment(selectedDate)
            .endOf("month")
            .add(5, "hours")
            .add(30, "minutes")
            .toISOString();
    case EDateTypes.MONTHLY:
      return isCurrentYear
        ? moment().add(5, "hours").add(30, "minutes").toISOString()
        : moment(selectedDate)
            .endOf("year")
            .add(5, "hours")
            .add(30, "minutes")
            .toISOString();
    default:
      return moment().add(5, "hours").add(30, "minutes").toISOString();
  }
};
