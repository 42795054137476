import { Container } from "react-bootstrap";
import styles from "./unauthorized.module.scss";

const UnAuthorized = () => {
  return (
    <Container>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          <div className={[styles.unAuthorizedHeading, "display-6"].join(" ")}>
            Page Unauthorized
          </div>
          <p className={[styles.unAuthorizedParagraph, " fs-6"].join(" ")}>
            The page you are looking for is unauthorized
          </p>
        </div>
      </div>
    </Container>
  );
};

export default UnAuthorized;
