import { Col, Row } from "react-bootstrap";
import styles from "./inventer-information.module.scss";

interface IInventerInfo {
  title: string;
  value: string | React.ReactNode;
}

interface IProps {
  inventerInfo: IInventerInfo[];
}

const InventerInformation = (props: IProps) => {
  return (
    <>
      {props.inventerInfo.map((i, index) => {
        return (
          <Row key={index} className="my-3">
            <Col className={`${styles.title} col-auto pe-0`}>{i.title}:</Col>
            <Col className={styles.value}>{i.value}</Col>
          </Row>
        );
      })}
    </>
  );
};

export default InventerInformation;
