import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import FilteringSidePanel from "../../../../../shared/oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import AppSelect from "../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import ArrowButton from "../../../../../shared/oversight-core/ui-elements/arrow-button/arrow-button";
import ButtonWithBadge from "../../../../../shared/oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import AvailableContract from "../available-contract/available-contract";

interface IProps {
  setSelectedButton: React.Dispatch<React.SetStateAction<number>>;
}

const AvailableContracts = (props: IProps) => {
  const { setSelectedButton } = props;
  const [isShowFilteringSidePanel, setIsShowFilteringSidePanel] =
    useState(false);
  return (
    <>
      <Row className="align-items-center">
        <Col className="col-auto">
          <ArrowButton onBack={() => setSelectedButton(0)} />
        </Col>
        <Col
          className="font-weight-500 text-dark cursor-pointer"
          onClick={() => {
            setSelectedButton(0);
          }}
        >
          Available Contracts
        </Col>
        <Col className="col-auto px-0">
          <ButtonWithBadge
            text="Filter"
            icon="filter_alt"
            onClick={() => {
              setIsShowFilteringSidePanel(true);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="px-0">
          <AvailableContract />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="px-0">
          <AvailableContract />
        </Col>
      </Row>
      <FilteringSidePanel
        isOpen={isShowFilteringSidePanel}
        onClose={() => {
          setIsShowFilteringSidePanel(false);
        }}
        onFilter={() => {
          setIsShowFilteringSidePanel(false);
        }}
      >
        <>
          <Row>
            <Col>
              <AppSelect
                label="No of Units"
                labelFontSize="font-size-12"
                options={[]}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <AppSelect
                label="Price"
                labelFontSize="font-size-12"
                options={[]}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <AppSelect
                label="Max Power"
                labelFontSize="font-size-12"
                options={[]}
              />
            </Col>
          </Row>
        </>
      </FilteringSidePanel>
    </>
  );
};

export default AvailableContracts;
