import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import SpinnerModal from "../../../oversight-core/ui-elements/spinner/spinner";
import NoChartDataView from "../no-chart-data-view/no-chart-data-view";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface IProps {
  borderColor1: string;
  borderColor2?: string;
  backgroundColor1: string;
  backgroundColor2?: string;
  label1: string;
  data1: Array<number>;
  hasSecondDataSet?: boolean;
  yAxesUnit: string;
  isFetching: boolean;
  labels: string[];
  spaceName: string;
  smartDeviceName: string;
  allDevicesDisconnected: boolean;
  filterType: "Space" | "Device";
}

const RealTimeAreaChart = (props: IProps) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 10,
    },
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
        align: "start" as const,
        labels: {
          usePointStyle: true,
          pointStyleWidth: 10,
          boxHeight: 7,
          font: {
            size: 14,
            family: "Poppins",
            weight: "normal" as const,
          },
          color: "#fff",
        },
      },
      tooltip: {
        yAlign: "bottom" as const,
        titleAlign: "center" as const,
        backgroundColor: "#ffffff",
        titleColor: "#383941",
        bodyColor: "#69768B",
        bodyFont: {
          size: 12,
          family: "Poppins",
          weight: "normal" as const,
        },
        titleFont: { size: 10, family: "Poppins" },
        displayColors: false,
        callbacks: {
          label: (context: any) => {
            const label = context.parsed.y + " " + props.yAxesUnit;

            return label;
          },
        },
      },
      datalabels: {
        display: false,
      },
      centerText: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          callback: (value: any) => {
            return value + " " + props.yAxesUnit;
          },
        },
        beginAtZero: true,
      },
    },
  };

  const legendMargin = {
    id: "legendMargin",
    beforeInit: (chart: any) => {
      const fitValue = chart.legend.fit;
      chart.legend.fit = () => {
        fitValue.bind(chart.legend)();
        return (chart.legend.height += 0);
      };
    },
  };

  const data1 = {
    labels: props.labels,
    datasets: [
      {
        fill: true,
        label: "",
        data: props.data1,
        borderColor: props.borderColor1,
        backgroundColor: props.backgroundColor1,
      },
    ],
  };

  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
        overflowY: "hidden",
        height: "100%",
      }}
    >
      <div
        style={{
          minWidth: "calc(100vw - 299px)",
          width: "1000px",
          height: "300px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {props.isFetching ? (
          <SpinnerModal show={props.isFetching} withOverlay={false} size="lg" />
        ) : (
          <>
            {props.allDevicesDisconnected && props.data1.length === 0 ? (
              <NoChartDataView
                isLiveData={true}
                from={
                  props.filterType === "Space"
                    ? props.spaceName
                    : props.smartDeviceName
                }
              />
            ) : (
              <Line data={data1} plugins={[legendMargin]} options={options} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RealTimeAreaChart;
