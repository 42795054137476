import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import AuctionContractModal from "../auction-contract-modal/auction-contract-modal";

interface IProps {
  isAlreadyBid?: boolean;
}

const AuctionContract = (props: IProps) => {
  const { isAlreadyBid } = props;

  const [isShowAuctionContractModal, setIsShowAuctionContractModal] =
    useState(false);

  return (
    <>
      <div className="bg-white rounded-4 p-3">
        <Row className="align-items-center">
          <Col xl={10}>
            <Row className="align-items-start">
              <Col xl={2}>
                <Row>
                  <Col className="text-dark font-weight-600">3 Units</Col>
                </Row>
                <Row>
                  <Col className="text-light font-weight-500">
                    Unplanned Outage Cover
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="mt-4 mt-xl-0">
                    <Row>
                      <Col className="font-size-10 text-light">Package ID</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">xl25578</Col>
                    </Row>
                  </Col>
                  <Col className="mt-4 mt-xl-0">
                    <Row>
                      <Col className="font-size-10 text-light">Period</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">10 Days</Col>
                    </Row>
                  </Col>
                  <Col className="mt-4 mt-xl-0">
                    <Row>
                      <Col className="font-size-10 text-light">Supplier</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">Leco</Col>
                    </Row>
                  </Col>
                  <Col className="mt-4 mt-xl-0">
                    <Row>
                      <Col className="font-size-10 text-light">Battery</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">
                        145-Maharagama
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mt-4 mt-xl-0">
                    <Row>
                      <Col className="font-size-10 text-light">Capacity</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">300 kWh</Col>
                    </Row>
                  </Col>
                  <Col className="mt-4 mt-xl-0">
                    <Row>
                      <Col className="font-size-10 text-light">Max Power</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">1 kW</Col>
                    </Row>
                  </Col>
                  <Col className="mt-4 mt-xl-0">
                    <div className="bg-gray-6 rounded-3 p-2">
                      <Row>
                        <Col className="font-size-10 text-light">
                          Highest Bid
                        </Col>
                      </Row>
                      <Row>
                        <Col className="font-weight-500 text-dark">
                          1200 LKR
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xl={2} className="mt-4 mt-xl-0">
            {isAlreadyBid ? (
              <div className="bg-gray-7 p-2 rounded-3">
                <Row className="align-items-center">
                  <Col>
                    <Row>
                      <Col className="font-size-10 text-light">Your Bid</Col>
                    </Row>
                    <Row>
                      <Col className="font-weight-500 text-dark">1200 LKR</Col>
                    </Row>
                  </Col>
                  <Col className="col-auto">
                    <MaterialIcon
                      icon="border_color"
                      size={10}
                      color="#1C1B1F"
                      className="px-2"
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <AppButton
                text="Bid"
                onClick={() => {
                  setIsShowAuctionContractModal(true);
                }}
              />
            )}
          </Col>
        </Row>
      </div>

      <AuctionContractModal
        show={isShowAuctionContractModal}
        onCancel={() => {
          setIsShowAuctionContractModal(false);
        }}
        onClose={() => {
          setIsShowAuctionContractModal(false);
        }}
      />
    </>
  );
};

export default AuctionContract;
