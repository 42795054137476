import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { EActiveStatus } from "../../../shared/oversight-core/enums/active-status";
import { EConnectionStatus } from "../../../shared/oversight-core/enums/connection-status";
import { EDateTypes } from "../../../shared/oversight-core/enums/date-types";
import { EDeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { ESmartControllerType } from "../../../shared/oversight-core/enums/smart-controller-type";
import { ESmartPlugType } from "../../../shared/oversight-core/enums/smart-plug-type";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import ISmartController from "../../../shared/oversight-core/interfaces/smart-controller";
import { IUsageFilter } from "../../../shared/oversight-core/interfaces/usage-filters";
import { RootState } from "../../store";

export const defaultSmartDevice: ISmartController = {
  id: "",
  serialKey: "",
  activeStatus: EActiveStatus.ENABLED,
  name: "",
  deviceConnectionState: EConnectionStatus.DISCONNECTED,
  linkedPowerConsumer: null,
  dynamicState: {
    powerState: EDeviceStatus.OFF,
    temperature: 0,
  },
  smartDeviceType: ESmartControllerType.SMART_PLUG,
  smartPlugType: ESmartPlugType.SPECIFIC,
};

const defaultFilters: IUsageFilter = {
  selectedSpace: { id: "", clusterId: "", name: "All Spaces" },
  selectedDeviceType: "",
  selectedDevice: { id: "", name: "" },
  selectedSmartDevice: { ...defaultSmartDevice },
  selectedRootSpace: { clusterId: "", spaceId: "" },
  isApplyForChildSpaces: true,
  selectedEntireSpace: undefined,
  from: moment().startOf("day").toISOString(),
  to: moment().toISOString(),
  viewType: EDateTypes.HOURLY,
};

export const usageFilterSlice = createSlice({
  name: "usageFilter",
  initialState: { ...defaultFilters },
  reducers: {
    setUsageFilter: (
      state,
      action: PayloadAction<Omit<IUsageFilter, "selectedRootSpace">>
    ) => {
      state.selectedSpace = { ...action.payload.selectedSpace };
      state.selectedDeviceType = action.payload.selectedDeviceType;
      state.selectedDevice = action.payload.selectedDevice;
      state.selectedSmartDevice = action.payload.selectedSmartDevice;
      state.isApplyForChildSpaces = action.payload.isApplyForChildSpaces;
      state.selectedEntireSpace = action.payload.selectedEntireSpace;
      state.from = action.payload.from;
      state.to = action.payload.to;
      state.viewType = action.payload.viewType;
    },
    setUsageFilterSelectedSpace: (
      state,
      action: PayloadAction<{
        id: string;
        clusterId: string;
        name: string;
        selectedEntireSpace: ISpaceView;
      }>
    ) => {
      state.selectedSpace = action.payload;
      state.selectedRootSpace = {
        clusterId: action.payload.clusterId,
        spaceId: action.payload.id,
      };
      state.selectedEntireSpace = action.payload.selectedEntireSpace;
    },
    setSelectedEntireSpace: (
      state,
      action: PayloadAction<{
        selectedEntireSpace: ISpaceView | undefined;
      }>
    ) => {
      state.selectedEntireSpace = action.payload.selectedEntireSpace;
    },
  },
});

export const {
  setUsageFilter,
  setUsageFilterSelectedSpace,
  setSelectedEntireSpace,
} = usageFilterSlice.actions;

export const selectUsageFilter = (state: RootState) => state.usageFilter;

export default usageFilterSlice.reducer;
