import { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { EEnergySourceType } from "../../enums/energy-source-type";
import { energySourceIconType } from "../../utils/energy-source-icon-type";
import { findEnergySourceType } from "../../utils/find-energy-source-type";
import MaterialIcon from "../material-icon/material-icon";

interface IProps {
  powerGeneratorType: EEnergySourceType;
  powerGenerationValue: number;
}

const GenerationInfo = (props: IProps) => {
  const { powerGeneratorType, powerGenerationValue } = props;

  const backgroundColor = useMemo(() => {
    switch (powerGeneratorType) {
      case EEnergySourceType.SOLAR_PV:
        return "#F7F8EE";
      case EEnergySourceType.WIND_TURBINE:
        return "#EEF7F8";
      case EEnergySourceType.GENERATOR:
        return "#FFF7F1";
      case EEnergySourceType.BATTERY:
        return "#EFEEF8";
      default:
        return "";
    }
  }, [powerGeneratorType]);

  const iconColor = useMemo(() => {
    switch (powerGeneratorType) {
      case EEnergySourceType.SOLAR_PV:
        return "#D08700";
      case EEnergySourceType.WIND_TURBINE:
        return "#015ACF";
      case EEnergySourceType.GENERATOR:
        return "#A4635D";
      case EEnergySourceType.BATTERY:
        return "#5E45B8";
      default:
        return "";
    }
  }, [powerGeneratorType]);

  return (
    <Row className="align-items-center">
      <Col
        className="col-auto p-3 rounded-2"
        style={{ backgroundColor: `${backgroundColor}` }}
      >
        <MaterialIcon
          icon={energySourceIconType(powerGeneratorType)}
          size={32}
          color={iconColor}
        />
      </Col>
      <Col>
        <Row className="align-items-center" style={{ lineHeight: "1.2" }}>
          <Col>
            <label className="font-size-24 text-dark font-weight-600">
              {powerGenerationValue}{" "}
              {powerGeneratorType === EEnergySourceType.BATTERY ? `%` : `kW`}
            </label>
            {powerGeneratorType === EEnergySourceType.BATTERY && (
              <label className="font-size-14 text-light font-weight-400 ps-2">
                (50kWh)
              </label>
            )}
          </Col>
        </Row>
        <Row style={{ lineHeight: "1.2" }}>
          <Col className="font-size-16 text-light font-weight-500">
            {findEnergySourceType(powerGeneratorType)}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default GenerationInfo;
