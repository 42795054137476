import { Col, Container, Row } from "react-bootstrap";
import MaterialIcon, {
  MtIcon,
} from "../../ui-elements/material-icon/material-icon";
import SpinnerModal from "../../ui-elements/spinner/spinner";

interface IProps {
  title: string;
  unitValue: number;
  unitText: string;
  billValue: number;
  billText: string;
  valueText: string;
  icon: MtIcon;
  iconColor: string;
  iconBackgroundColor: string;
  isLoading: boolean;
}

const BillInfoCard = (props: IProps) => {
  const {
    title,
    unitValue,
    unitText,
    billValue,
    billText,
    valueText,
    icon,
    iconColor,
    iconBackgroundColor,
    isLoading,
  } = props;
  return (
    <Container fluid className="bg-white rounded-3 py-3">
      {!isLoading ? (
        <>
          <Row className="align-items-center">
            <Col className="col-auto">
              <MaterialIcon
                icon={icon}
                style={{ backgroundColor: iconBackgroundColor }}
                color={iconColor}
                className="p-3"
              />
            </Col>
            <Col className="text-light font-weight-500">{title}</Col>
          </Row>
          <Row className="mt-4">
            <Col className="font-weight-600 font-size-24 text-dark">{`${unitValue} ${unitText}`}</Col>
          </Row>
          <Row className="mt-2">
            <Col className="font-weight-600 font-size-24 text-dark">{`${billValue} ${billText}`}</Col>
          </Row>
          <Row className="mt-2">
            <Col className="font-weight-500 text-light">{valueText}</Col>
          </Row>
        </>
      ) : (
        <SpinnerModal show={isLoading} withOverlay={false} />
      )}
    </Container>
  );
};

export default BillInfoCard;
