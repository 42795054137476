import { Col, Row } from "react-bootstrap";
import OutageInfo from "../outage-info/outage-info";
import PlannedBidContract from "../planned-bid-contract/planned-bid-contract";
import PlannedLifeLinePowerTimeline from "../planned-life-line-power-timeline/planned-life-line-power-timeline";

const PlannedLifeLinePowerTimelineContainer = () => {
  return (
    <div>
      <Row>
        <Col>
          <PlannedLifeLinePowerTimeline isUnable={false} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <PlannedLifeLinePowerTimeline isUnable={true} isCompleted={false} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <PlannedBidContract />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <OutageInfo />
        </Col>
      </Row>
    </div>
  );
};

export default PlannedLifeLinePowerTimelineContainer;
