import { Col, Row } from "react-bootstrap";
import UnplannedLifeLinePowerTimeline from "../unplanned-life-line-power-timeline/unplanned-life-line-power-timeline";

const UnplannedLifeLinePowerTimelineContainer = () => {
  return (
    <>
      <Row className="mt-4">
        <Col>
          <UnplannedLifeLinePowerTimeline
            date="2024/10/19 4.00am - 7.00am"
            value={-0.45}
            isFirst={true}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <UnplannedLifeLinePowerTimeline
            date="2024/10/18 4.00am - 7.00am"
            value={3}
            isLast={true}
          />
        </Col>
      </Row>
    </>
  );
};

export default UnplannedLifeLinePowerTimelineContainer;
