import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import styles from "./outage-info.module.scss";

const OutageInfo = () => {
  return (
    <>
      <Row className="align-items-center">
        <Col className="col-auto">
          <div className={`${styles.indicator} bg-red rounded-circle`}></div>
        </Col>
        <Col className="text-light">23 Mon 12:00 PM - 23 Mon 03:00 PM</Col>
      </Row>
      <Row className="mt-4">
        <Col className="px-5">
          <div className="bg-white rounded-3 p-3">
            <Row className="align-items-center">
              <Col className="col-auto">
                <MaterialIcon icon="error" color="#E03939" />
              </Col>
              <Col className="text-dark">
                Unable to provide power due to maintenance in the feeder.
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OutageInfo;
