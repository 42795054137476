import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EDateTypes } from "../../../shared/oversight-core/enums/date-types";
import ISolarFilters from "../../../shared/oversight-core/interfaces/solar-filters";
import { RootState } from "../../store";

const defaultFilters: ISolarFilters = {
  selectedDate: new Date(),
  selectedDateType: EDateTypes.DAILY,
};

export const solarFilterSlice = createSlice({
  name: "solarFilter",
  initialState: { ...defaultFilters },
  reducers: {
    setSolarFilter: (state, action: PayloadAction<ISolarFilters>) => {
      state.selectedDate = action.payload.selectedDate;
      state.selectedDateType = action.payload.selectedDateType;
    },
  },
});

export const { setSolarFilter } = solarFilterSlice.actions;

export const selectSolarFilter = (state: RootState) => state.solarFilter;

export default solarFilterSlice.reducer;
