import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { ISolarPlantInverterView } from "../../interfaces/solar-plant-inverter-view";
import Gauge from "../../ui-elements/gauge/gauge";
import Helper from "../../utils/helpers";
import styles from "./inventer-info-card.module.scss";
import InventerInformation from "./inventer-information/inventer-information";

interface IProps {
  solarPlantInventer: ISolarPlantInverterView;
}

const InventerInfoCard = (props: IProps) => {
  const { solarPlantInventer } = props;

  return (
    <>
      <div className={`${styles.infoCardContainer} py-4 px-5`}>
        <Row className="align-items-center">
          <Col className="text-dark font-weight-500 font-size-24">
            {solarPlantInventer.modelType}
          </Col>
        </Row>
        <Row className="align-items-center justify-content-between my-1 g-4">
          <Col className="col-12 col-lg-auto">
            <Row className="justify-content-center justify-content-lg-start">
              <Col className="col-auto">
                <Gauge
                  icon="solar_power"
                  value={solarPlantInventer.dailyGeneration}
                  maxValue={solarPlantInventer.capacity}
                  circleRatio={1}
                  unit={"kWh"}
                  trailColor="#D08700"
                  width={150}
                  height={75}
                  isDifferentStyles
                />
              </Col>
            </Row>
            <Row className="justify-content-center mt-5 pt-5">
              <Col className="col-auto text-light font-weight-500 font-size-16">
                Today Generation
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-sm-6 col-lg-auto">
            <InventerInformation
              inventerInfo={[
                { title: "Model", value: solarPlantInventer.modelType },
                { title: "SIN", value: solarPlantInventer.inverterSN },
                { title: "Check Code", value: solarPlantInventer.checkCode },
                {
                  title: "Rated Power",
                  value: `${solarPlantInventer.capacity} kW`,
                },
                {
                  title: "Connected",
                  value: moment(solarPlantInventer.connected)
                    .utcOffset("+05:30")
                    .format("YYYY-MM-DD HH:mm"),
                },
              ]}
            />
          </Col>
          <Col className="col-12 col-sm-6 col-lg-auto">
            <InventerInformation
              inventerInfo={[
                { title: "Power", value: `${solarPlantInventer.gridPower} kW` },
                {
                  title: "Inner Temp",
                  value: <>{solarPlantInventer.innerTemperature} C&deg;</>,
                },
                {
                  title: "AC Voltage",
                  value: `${solarPlantInventer.acVoltage1} V`,
                },
                {
                  title: "AC Current",
                  value: `${solarPlantInventer.acCurrent1} A`,
                },
                {
                  title: "AC Frequency",
                  value: `${solarPlantInventer.acFrequency1} Hz`,
                },
              ]}
            />
          </Col>
          <Col className="col-12 col-xxl-auto">
            <Row className="g-4">
              <Col className={` col-12 col-sm-6 col-xxl-12`}>
                <div className={styles.background1}>
                  <div className="text-light font-weight-500 font-size-16">
                    Total Generation
                  </div>
                  <div className="text-dark font-weight-500 font-size-16 mt-2">
                    {Helper.roundTo2(solarPlantInventer.totalGeneration)} kWh
                  </div>
                </div>
              </Col>
              <Col className={` col-12 col-sm-6 col-xxl-12`}>
                <div className={styles.background1}>
                  <div className="text-light font-weight-500 font-size-16">
                    Total Hours
                  </div>
                  <div className="text-dark font-weight-500 font-size-16 mt-2">
                    {solarPlantInventer.hourTotal} H
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InventerInfoCard;
