import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import OutageCover from "../../../shared/oversight-core/shared-components/outage-cover/outage-cover";
import BarChart from "../../../shared/oversight-core/ui-elements/bar-chart/bar-chart";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import styles from "./life-line-power.module.scss";

const LifeLinePower = () => {
  const isAddedUnplannedOutageCover = false;

  const navigate = useNavigate();

  return (
    <>
      <div className="container-white py-4">
        <Row className="align-items-center">
          <Col className={`${styles.iconBackground} col-auto p-3`}>
            <MaterialIcon icon="ecg_heart" color="#CA3E3E" />
          </Col>
          <Col className="text-dark font-weight-500 font-size-16">
            Active Packager
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="col-12 col-xl">
            <OutageCover
              title="Planed Outage Cover"
              type="Planed"
              onClick={() => {
                navigate(AppRoute.LIFE_LINE_POWER_PLANNED);
              }}
            />
          </Col>
          <Col
            className={`mt-3 mt-xl-0 ${
              !isAddedUnplannedOutageCover && styles.dashContainer
            }`}
            onClick={() => navigate(AppRoute.LIFE_LINE_POWER_UNPLANNED)}
          >
            {isAddedUnplannedOutageCover ? (
              <OutageCover
                title="Unplanned Outage Cover"
                type="Unplanned"
                onClick={() => navigate(AppRoute.LIFE_LINE_POWER_UNPLANNED)}
              />
            ) : (
              <>
                <MaterialIcon icon="add_circle" color="#69768B" />
                <div className="text-light font-weight-500 font-size-16 mt-3">
                  Add Unplanned Outage Cover Package
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
      <div className="container-white mt-4">
        <BarChart
          data1={[12, 20, 5, 7, 10, 8]}
          data2={[8, 10, 7, 5, 20, 12]}
          labels={[
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9 ",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
          ]}
          borderColor1="#986993"
          borderColor2="#EB9191"
          backgroundColor1="#986993"
          backgroundColor2="#EB9191"
          yAxesUnit="unit"
          hasSecondDataSet={true}
          borderRadius={100}
          dataLabel1="Planed Outage"
          dataLabel2="Unplanned Outage"
          isDisplayLegend
          titleCallBack={(tooltipItems: any) => {
            return tooltipItems[0].dataset.label;
          }}
        />
        <Row className="mt-3">
          <Col className="text-light text-center font-size-16 font-weight-500">
            Monthly Usage
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LifeLinePower;
