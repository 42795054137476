import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import ProgressBar from "../../../../../shared/oversight-core/shared-components/progress-bar/progress-bar";
import AppDatePicker from "../../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";

interface IProps {
  selectedDate?: Date;
  isShowDatePicker?: boolean;
  setSelectedDate?: React.Dispatch<React.SetStateAction<Date>>;
  onArrowClick?: () => void;
  isLoading: boolean;
  day: number;
  peak: number;
  offPeak: number;
}

const UsageByTime = (props: IProps) => {
  const {
    selectedDate,
    isShowDatePicker,
    isLoading,
    day,
    peak,
    offPeak,
    setSelectedDate,
    onArrowClick,
  } = props;

  return (
    <Container fluid className="bg-white py-3 rounded-3">
      <Row className="align-items-center justify-content-between">
        <Col className="font-size-14 font-weight-500 text-dark">
          Usage By Time
        </Col>
        {isShowDatePicker && selectedDate && setSelectedDate && (
          <Col xs={7} sm={5} lg={4} xl={3}>
            <AppDatePicker
              selectedDate={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
              }}
              monthYearPicker={true}
              maxDate={moment().endOf("month").toDate()}
            />
          </Col>
        )}
      </Row>
      {!isLoading ? (
        <Row className="mt-4 align-items-center">
          <Col>
            <Row className="align-items-center">
              <Col className="ps-lg-0 col-12 col-lg">
                <ProgressBar
                  text="Day Time"
                  percentage={day}
                  icon="clear_day"
                  containerColor="#FFF9E9"
                  progressBarColor="#FBE6A1"
                  iconColor="#D8AB17"
                />
              </Col>
              <Col className="mt-4 mt-lg-0 col-12 col-lg">
                <ProgressBar
                  text="Peak Time"
                  percentage={peak}
                  icon="alarm"
                  containerColor="#FFEFEF"
                  progressBarColor="#F9CFCF"
                  iconColor="#C67272"
                />
              </Col>
              <Col className="mt-4 mt-lg-0 col-12 col-lg">
                <ProgressBar
                  text="Off Peak Time"
                  percentage={offPeak}
                  icon="alarm_off"
                  containerColor="#EBF8FC"
                  progressBarColor="#C9E3EA"
                  iconColor="#3A869B"
                />
              </Col>

              {!isShowDatePicker && (
                <Col className="col-auto d-none d-lg-block">
                  <MaterialIcon
                    icon="chevron_right"
                    color="#69768B"
                    className="cursor-pointer"
                    onClick={() => {
                      onArrowClick && onArrowClick();
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
          {!isShowDatePicker && (
            <Col className="col-auto d-lg-none">
              <MaterialIcon
                icon="chevron_right"
                color="#69768B"
                className="cursor-pointer"
                onClick={() => {
                  onArrowClick && onArrowClick();
                }}
              />
            </Col>
          )}
        </Row>
      ) : (
        <Row>
          <Col>
            <SpinnerModal show={isLoading} withOverlay={false} />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default UsageByTime;
