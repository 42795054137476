import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useLazyViewDailyExternalConsumptionDataQuery,
  useLazyViewExternalConsumptionDataPercentagesQuery,
  useLazyViewExternalConsumptionDataQuery,
} from "../../../../../redux/api/usage/usageAPI";
import { selectBillingSpaceFilter } from "../../../../../redux/features/billing-space-filter/billing-space-filter-slice";
import { IDailyExternalConsumption } from "../../../../../shared/oversight-core/dtos/response-dtos/view-daily-external-consumption-data-response-dto";
import { IExternalConsumptionPercentage } from "../../../../../shared/oversight-core/dtos/response-dtos/view-external-consumption-data-percentages-response-dto";
import { IExternalConsumptionDataReadingData } from "../../../../../shared/oversight-core/dtos/response-dtos/view-external-consumption-data-response-dto";
import { EDemandPeriod } from "../../../../../shared/oversight-core/enums/demand-period";
import BillInfoCard from "../../../../../shared/oversight-core/shared-components/bill-info-card/bill-info-card";
import ImportExportInfoCard from "../../../../../shared/oversight-core/shared-components/import-export-info-card/import-export-info-card";
import MeterInfoCard from "../../../../../shared/oversight-core/shared-components/meter-info-card/meter-info-card";
import ArrowButton from "../../../../../shared/oversight-core/ui-elements/arrow-button/arrow-button";
import BarChart from "../../../../../shared/oversight-core/ui-elements/bar-chart/bar-chart";
import NoChartDataView from "../../../../../shared/oversight-core/ui-elements/no-chart-data-view/no-chart-data-view";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import UsageByTime from "../usage-by-time/usage-by-time";

const MeterDashboard = () => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<Date>(
    moment().startOf("month").toDate()
  );
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const [
    externalConsumptionDataPercentages,
    setExternalConsumptionDataPercentages,
  ] = useState<IExternalConsumptionPercentage[]>([]);
  const [readingData, setReadingData] =
    useState<IExternalConsumptionDataReadingData>();
  const [dayDaily, setDayDaily] = useState<string[]>([]);
  const [peakDaily, setPeakDaily] = useState<string[]>([]);
  const [offPeakDaily, setOffPeakDaily] = useState<string[]>([]);
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [
    triggerViewExternalConsumptionDataPercentagesData,
    { isFetching: isFetchingViewExternalConsumptionDataPercentages },
  ] = useLazyViewExternalConsumptionDataPercentagesQuery();
  const [
    triggerViewExternalConsumptionData,
    { isFetching: isFetchingViewExternalConsumptionData },
  ] = useLazyViewExternalConsumptionDataQuery();
  const [
    triggerViewDailyExternalConsumptionData,
    { isFetching: isFetchingViewDailyExternalConsumptionData },
  ] = useLazyViewDailyExternalConsumptionDataQuery();

  const sortDailyChartData = (dailyChartData: IDailyExternalConsumption[]) => {
    dailyChartData.sort((a, b) => {
      return moment(a.date).date() - moment(b.date).date();
    });

    console.log(dailyChartData);

    return dailyChartData;
  };

  useEffect(() => {
    if (
      selectedDate &&
      billingSpaceFilter &&
      billingSpaceFilter.spaceCluster.serviceProviderAccount.accountNumber
    ) {
      triggerViewExternalConsumptionDataPercentagesData({
        year: moment(selectedDate).year(),
        month: moment(selectedDate).month() + 1,
        accountNumber:
          billingSpaceFilter.spaceCluster.serviceProviderAccount.accountNumber,
      })
        .unwrap()
        .then((res) => {
          setExternalConsumptionDataPercentages(res.percentages);
        })
        .catch(() => {
          setExternalConsumptionDataPercentages([]);
        });

      triggerViewExternalConsumptionData({
        year: moment(selectedDate).year(),
        month: moment(selectedDate).month() + 1,
        accountNumber:
          billingSpaceFilter.spaceCluster.serviceProviderAccount.accountNumber,
      })
        .unwrap()
        .then((res) => {
          setReadingData(res.meterReading.readingData);
        })
        .catch(() => {
          setReadingData(undefined);
        });

      triggerViewDailyExternalConsumptionData({
        year: moment(selectedDate).year(),
        month: moment(selectedDate).month() + 1,
        accountNumber:
          billingSpaceFilter.spaceCluster.serviceProviderAccount.accountNumber,
      })
        .unwrap()
        .then((res) => {
          const sortedChart = sortDailyChartData([...res.dailyConsumption]);

          setChartLabels(
            sortedChart.map((dc) => String(moment(dc.date).date()))
          );
          setDayDaily(
            sortedChart.map(
              (dc) =>
                dc.readings
                  .find((reading) => reading.demandPeriod === EDemandPeriod.DAY)
                  ?.consumption.toFixed(2) || "0"
            )
          );
          setPeakDaily(
            sortedChart.map(
              (dc) =>
                dc.readings
                  .find(
                    (reading) => reading.demandPeriod === EDemandPeriod.PEAK
                  )
                  ?.consumption.toFixed(2) || "0"
            )
          );
          setOffPeakDaily(
            sortedChart.map(
              (dc) =>
                dc.readings
                  .find(
                    (reading) => reading.demandPeriod === EDemandPeriod.OFF_PEAK
                  )
                  ?.consumption.toFixed(2) || "0"
            )
          );
        })
        .catch(() => {
          setChartLabels([]);
        });
    }
  }, [
    selectedDate,
    billingSpaceFilter.spaceCluster.serviceProviderAccount.accountNumber,
  ]);

  return (
    <Container fluid>
      <Row className="mx-0">
        <Col>
          <ArrowButton onBack={() => navigate(-1)} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <UsageByTime
            isShowDatePicker={true}
            selectedDate={selectedDate}
            isLoading={isFetchingViewExternalConsumptionDataPercentages}
            setSelectedDate={setSelectedDate}
            day={
              externalConsumptionDataPercentages.find(
                (ecdp) => ecdp.demandPeriod === EDemandPeriod.DAY
              )?.percentage || 0
            }
            peak={
              externalConsumptionDataPercentages.find(
                (ecdp) => ecdp.demandPeriod === EDemandPeriod.PEAK
              )?.percentage || 0
            }
            offPeak={
              externalConsumptionDataPercentages.find(
                (ecdp) => ecdp.demandPeriod === EDemandPeriod.OFF_PEAK
              )?.percentage || 0
            }
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6} xl={3} className="d-flex align-items-stretch">
          <MeterInfoCard
            title="Meter Info"
            maxDemand={readingData?.maximumDemand || 0}
            phaseAVoltage={readingData?.phaseAvoltage || 0}
            phaseBVoltage={readingData?.phaseBvoltage || 0}
            phaseCVoltage={readingData?.phaseCvoltage || 0}
            phaseACurrent={readingData?.phaseAcurrent || 0}
            phaseBCurrent={readingData?.phaseBcurrent || 0}
            phaseCCurrent={readingData?.phaseCcurrent || 0}
            isLoading={isFetchingViewExternalConsumptionData}
          />
        </Col>
        <Col md={6} xl={3} className="d-flex align-items-stretch mt-2 mt-md-0">
          <ImportExportInfoCard
            title="Export"
            unit="Unit"
            icon="upload_2"
            iconColor="#3A9D49"
            iconBackgroundColor="#F5FFEE"
            value={readingData?.exportTotal || 0}
            valueText="Total Export"
            day={readingData?.exportTR1 || 0}
            peak={readingData?.exportTR2 || 0}
            offPeak={readingData?.exportTR3 || 0}
            isLoading={isFetchingViewExternalConsumptionData}
          />
        </Col>
        <Col md={6} xl={3} className="d-flex align-items-stretch mt-2 mt-xl-0">
          <ImportExportInfoCard
            title="Import"
            unit="Unit"
            icon="download_2"
            iconColor="#B56B4C"
            iconBackgroundColor="#FEF5ED"
            value={readingData?.importTotal || 0}
            valueText="Total Import"
            day={readingData?.importTR1 || 0}
            peak={readingData?.importTR2 || 0}
            offPeak={readingData?.importTR3 || 0}
            isLoading={isFetchingViewExternalConsumptionData}
          />
        </Col>
        <Col md={6} xl={3} className="d-flex align-items-stretch mt-2 mt-xl-0">
          <BillInfoCard
            title="Billed Value"
            unitValue={0}
            unitText="Unit"
            billValue={0}
            billText="LKR"
            valueText="Total Bill"
            icon="request_quote"
            iconColor="#4D5EA0"
            iconBackgroundColor="#EEF9FF"
            isLoading={isFetchingViewExternalConsumptionData}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div className="bg-white p-3 rounded-3">
            {!isFetchingViewDailyExternalConsumptionData ? (
              dayDaily.some((dd) => dd !== "0.00") ||
              peakDaily.some((pd) => pd !== "0.00") ||
              offPeakDaily.some((opd) => opd !== "0.00") ? (
                <BarChart
                  data1={dayDaily}
                  data2={peakDaily}
                  data3={offPeakDaily}
                  labels={chartLabels}
                  borderColor1="#ECD58A"
                  borderColor2="#E79F9F"
                  borderColor3="#8ABECC"
                  backgroundColor1="#ECD58A"
                  backgroundColor2="#E79F9F"
                  backgroundColor3="#8ABECC"
                  yAxesUnit="unit"
                  hasThirdDataSet={true}
                  stacked={true}
                  borderRadius={50}
                  dataLabel1="Day Time"
                  dataLabel2="Peak Time"
                  dataLabel3="Off Peak Time"
                  titleCallBack={(tooltipItems: any) => {
                    return tooltipItems[0].dataset.label;
                  }}
                  isDisplayLegend={true}
                />
              ) : (
                <NoChartDataView />
              )
            ) : (
              <SpinnerModal
                show={isFetchingViewDailyExternalConsumptionData}
                withOverlay={false}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MeterDashboard;
