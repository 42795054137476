import AddAccountNumbersRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/add-account-numbers-request-dto";
import RegisteringUsersDTO from "../../../shared/oversight-core/dtos/request-dtos/registering-users-dto";
import UpdateAccountNumberRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/update-account-number-request-tdo";
import UpdateUserProfileRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/update-user-profile-request-dto";
import AddAccountNumbersResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/add-account-numbers-response-dto";
import CheckUserAvailabilityResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/check-user-availability-response-dto";
import LoginResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/login-response-dto";
import RegisteringUsersResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/registering-user-response-dto";
import UpdateAccountNumberResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/update-account-number-response-dto";
import UpdateUserProfileResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/update-user-profile-response-dto";
import ViewMobileNumberResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-mobile-number-response-dto";
import ViewUnAssignedAccountNumbersResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-un-assigned-account-numbers-response-dto";
import IExtendedUserProfile from "../../../shared/oversight-core/interfaces/extendedUserProfile";
import { IUserProfile } from "../../../shared/oversight-core/interfaces/user-profile";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { setPrivileges, setToken } from "../../features/auth/auth-slice";
import { setProfile } from "../../features/user/user-slice";
import { rootApi } from "../apiManager";

export const userAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    profile: builder.query<IUserProfile, void>({
      transformErrorResponse,
      query() {
        return {
          url: "consumer/current/view",
          method: "GET",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const res = await queryFulfilled;
        dispatch(setProfile(res.data));
      },
    }),
    getExtendedProfile: builder.query<IExtendedUserProfile, void>({
      transformErrorResponse,
      query() {
        return {
          url: `user/current/profile/view`,
          method: "GET",
        };
      },
    }),
    forgotPasswordEmail: builder.query<
      void,
      {
        email: string;
      }
    >({
      transformErrorResponse,
      query({ email }) {
        return {
          url: `user/forgot-password/email/${email}`,
          method: "GET",
        };
      },
    }),
    forgotPasswordMobileNumber: builder.query<
      void,
      {
        mobileNumber: string;
      }
    >({
      transformErrorResponse,
      query({ mobileNumber }) {
        return {
          url: `user/forgot-password/mobile-number/${mobileNumber}`,
          method: "GET",
        };
      },
    }),
    registrationResendOTP: builder.query<
      void,
      {
        mobileNumber: string;
      }
    >({
      transformErrorResponse,
      query({ mobileNumber }) {
        return {
          url: `user/mobile-number/${mobileNumber}/verify`,
          method: "GET",
        };
      },
    }),
    validateOTP: builder.query<
      LoginResponseDTO,
      {
        mobileNumber: string;
        otp: string;
      }
    >({
      transformErrorResponse,
      query({ mobileNumber, otp }) {
        return {
          url: `user/validate-otp/mobile-number/${mobileNumber}`,
          method: "POST",
          body: { otp },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const res = await queryFulfilled;
        localStorage.setItem("token", res.data.token);
        localStorage.setItem(
          "privileges",
          JSON.stringify(res.data.authorities)
        );
        dispatch(setToken(res.data.token));
        dispatch(setPrivileges(res.data.authorities));
      },
    }),
    resetPassword: builder.query<
      void,
      {
        password: string;
      }
    >({
      transformErrorResponse,
      query({ password }) {
        return {
          url: `user/reset-password`,
          method: "POST",
          body: { password },
        };
      },
    }),
    addUser: builder.mutation<RegisteringUsersResponseDTO, RegisteringUsersDTO>(
      {
        transformErrorResponse,
        query(data) {
          return {
            url: "user/register",
            method: "POST",
            body: data,
          };
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const res = await queryFulfilled;
            localStorage.setItem("token", res.data.token);
            localStorage.setItem(
              "privileges",
              JSON.stringify(res.data.authorities)
            );
            dispatch(setToken(res.data.token));
            dispatch(setPrivileges(res.data.authorities));
          } catch (error) {
            console.log(error);
          }
        },
      }
    ),
    updateUserProfile: builder.mutation<
      UpdateUserProfileResponseDTO,
      UpdateUserProfileRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: "user/current/profile/update",
          method: "PUT",
          body: data,
        };
      },
    }),
    getUserValidation: builder.query<
      CheckUserAvailabilityResponseDTO,
      { username?: string; email?: string; mobileNumber?: string; nic?: string }
    >({
      transformErrorResponse,
      query({ username, email, mobileNumber, nic }) {
        return {
          url: `user/check-availability`,
          method: "GET",
          params: { username, email, mobileNumber, nic },
        };
      },
    }),
    addAccountNumber: builder.mutation<
      AddAccountNumbersResponseDTO,
      AddAccountNumbersRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: "consumer/service-provider-account/register",
          method: "POST",
          body: data,
        };
      },
    }),
    updateAccountNumber: builder.mutation<
      UpdateAccountNumberResponseDTO,
      UpdateAccountNumberRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `consumer/service-provider-account/update`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    viewUnAssignedAccountNumber: builder.query<
      ViewUnAssignedAccountNumbersResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `consumer/account-number/unassigned/view`,
          method: "GET",
        };
      },
    }),
    deleteAccountNumber: builder.mutation<void, { accountNumber: string }>({
      transformErrorResponse,
      query(data) {
        return {
          url: `consumer/service-provider-account/unregister`,
          method: "DELETE",
          body: data,
        };
      },
    }),
    updateMobileNumber: builder.mutation<
      RegisteringUsersResponseDTO,
      { contactNumber: string }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `user/current/contact-number/update`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    updateEmail: builder.mutation<
      RegisteringUsersResponseDTO,
      { email: string }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `user/current/email/update`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    viewMobileNumber: builder.query<ViewMobileNumberResponseDTO, void>({
      transformErrorResponse,
      query() {
        return {
          url: `consumer/current/mobile-number/view`,
          method: "GET",
        };
      },
    }),
    deleteUser: builder.mutation<void, void>({
      transformErrorResponse,
      query() {
        return {
          url: `user/unregister`,
          method: "DELETE",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyProfileQuery,
  useLazyForgotPasswordEmailQuery,
  useLazyForgotPasswordMobileNumberQuery,
  useLazyRegistrationResendOTPQuery,
  useLazyGetExtendedProfileQuery,
  useLazyValidateOTPQuery,
  useLazyResetPasswordQuery,
  useAddUserMutation,
  useGetUserValidationQuery,
  useLazyGetUserValidationQuery,
  useLazyViewUnAssignedAccountNumberQuery,
  useAddAccountNumberMutation,
  useUpdateAccountNumberMutation,
  useUpdateMobileNumberMutation,
  useUpdateEmailMutation,
  useLazyViewMobileNumberQuery,
  useUpdateUserProfileMutation,
  useDeleteAccountNumberMutation,
  useDeleteUserMutation,
} = userAPI;
