import { Col, Row } from "react-bootstrap";
import DoughnutChartTypeTwo from "../../../../../../shared/oversight-core/ui-elements/doughnut-chart-type-two/doughnut-chart-type-two";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import styles from "./planned-life-line-power-timeline.module.scss";

interface IProps {
  isUnable: boolean;
  isCompleted?: boolean;
}

const PlannedLifeLinePowerTimeline = (props: IProps) => {
  const { isUnable, isCompleted = true } = props;

  return (
    <>
      <Row className="align-items-center">
        <Col className="col-auto">
          <div
            className={`${styles.indicator} ${
              isUnable ? "bg-red" : "bg-primary"
            } rounded-circle`}
          ></div>
        </Col>
        <Col className="text-light">23 Mon 12:00 PM - 23 Mon 03:00 PM</Col>
      </Row>
      <Row>
        <Col className="px-5">
          <div className="mt-4 bg-white rounded-3 p-3">
            <Row className="align-items-center">
              <Col className="col-sm-auto">
                <DoughnutChartTypeTwo
                  percentage={75}
                  primaryBackgroundColor="#B66E6E"
                  primaryBorderColor="#B66E6E"
                  backgroundColor="#F2EEF9"
                  borderColor="#F2EEF9"
                />
              </Col>
              <Col xs={12} className="col-sm">
                <Row className="align-items-center">
                  <Col xs={12} className="col-sm mt-4 mt-sm-0 col-lg-auto">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <Row>
                          <Col className="font-weight-600 font-size-32 text-dark">
                            2 Units
                          </Col>
                        </Row>
                        <Row>
                          <Col className="font-weight-500 text-light">
                            Used from 2 Units
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <MaterialIcon icon="info" color="#2754a8" />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="col-lg mt-4 mt-lg-0">
                    <Row className="align-items-start">
                      <Col sm={12} className="col-xl col-xxl-auto">
                        <Row className="align-items-start">
                          <Col className="col-auto">
                            <Row>
                              <Col className="font-size-10 text-light">
                                Package ID
                              </Col>
                            </Row>
                            <Row>
                              <Col className="font-weight-500 text-dark">
                                Lp11545
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-auto">
                            <Row>
                              <Col className="font-size-10 text-light">
                                Capacity
                              </Col>
                            </Row>
                            <Row>
                              <Col className="font-weight-500 text-dark">
                                300 kWh
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-auto">
                            <Row>
                              <Col className="font-size-10 text-light">
                                Max Power
                              </Col>
                            </Row>
                            <Row>
                              <Col className="font-weight-500 text-dark">
                                1 kW
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={12} className="mt-2 mt-xxl-0 col-xxl-auto">
                        <Row className="align-items-start">
                          <Col className="col-auto">
                            <Row>
                              <Col className="font-size-10 text-light">
                                Supplier
                              </Col>
                            </Row>
                            <Row>
                              <Col className="font-weight-500 text-dark">
                                LECO
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-auto">
                            <Row>
                              <Col className="font-size-10 text-light">
                                Battery
                              </Col>
                            </Row>
                            <Row>
                              <Col className="font-weight-500 text-dark">
                                145-Maharagama
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-auto">
                            <Row>
                              <Col className="font-size-10 text-light">
                                Price
                              </Col>
                            </Row>
                            <Row>
                              <Col className="font-weight-500 text-dark">
                                1125 LKR
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="text-light font-weight-500 mt-4 mb-5 my-lg-0 col-lg-auto">
                    {isCompleted ? (
                      "Completed"
                    ) : (
                      <Col>
                        <Row>
                          <Col className="col-auto">
                            <MaterialIcon
                              icon="block"
                              color="#CA3E3E"
                              className="cursor-pointer"
                            />
                          </Col>
                          <Col>
                            <MaterialIcon
                              icon="cached"
                              color="#302B89"
                              className="cursor-pointer"
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PlannedLifeLinePowerTimeline;
