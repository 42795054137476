import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectGenerationFilter,
  setGenerationFilter,
} from "../../../redux/features/generation-filter/generation-filter-slice";
import { EEnergySourceType } from "../../../shared/oversight-core/enums/energy-source-type";
import { EPowerMeasureType } from "../../../shared/oversight-core/enums/power-measure-type";
import { EUnitType } from "../../../shared/oversight-core/enums/unit-type";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import GenerationInfoCard from "../../../shared/oversight-core/shared-components/generation-info-card/generation-info-card";
import ActiveInactiveIndicator from "../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import ButtonWithBadge from "../../../shared/oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import EnergySourceInfo from "../../../shared/oversight-core/ui-elements/energy-source-info/energy-source-info";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import { energySourceIconType } from "../../../shared/oversight-core/utils/energy-source-icon-type";
import { findEnergySourceType } from "../../../shared/oversight-core/utils/find-energy-source-type";
import Helper from "../../../shared/oversight-core/utils/helpers";
import { powerConvertor } from "../../../shared/oversight-core/utils/power-convertor";
import GenerationFilterSidePanel from "./components/generation-filter-side-panel/generation-filter-side-panel";

interface ChartData {
  solarData: number[];
  windTurbineData: number[];
  generatorData: number[];
  labels: string[];
}

const defaultChartDataValues = {
  solarData: [],
  windTurbineData: [],
  generatorData: [],
  labels: [],
};

const unitTypes: Option[] = [
  {
    value: EUnitType.POWER,
    label: "Power(kW)",
  },
  {
    value: EUnitType.ENERGY,
    label: "Energy(kWh)",
  },
];

const Generation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const generationFilterStore = useSelector(selectGenerationFilter);

  const [selectedUnitType, setSelectedUnitType] = useState<Option>({
    ...unitTypes[0],
  });
  const [chartData, setChartData] = useState<ChartData>({
    ...defaultChartDataValues,
  });
  const [totalEnergyGeneration, setTotalEnergyGeneration] = useState<{
    [key: string]: number;
  }>({});
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    setTotalEnergyGeneration({
      GENERATOR: 0,
      WIND_TURBINE: 14000,
      SOLAR_PV: 124000,
    });
  }, []);

  const onFilter = (selectedTotalGenerationDate: Date) => {
    dispatch(
      setGenerationFilter({
        selectedTotalGenerationDate: selectedTotalGenerationDate.getTime(),
      })
    );
    setShowFilter(false);
  };

  const onClearAll = () => {
    dispatch(
      setGenerationFilter({
        selectedTotalGenerationDate: new Date().getTime(),
      })
    );
    setShowFilter(false);
  };

  const cards = [
    {
      powerGeneratorType: EEnergySourceType.SOLAR_PV,
      powerGenerationValue: 2.04,
      energyGenerationValue: 4.2,
      onClick: () => {
        navigate(AppRoute.SOLAR);
      },
    },
    {
      powerGeneratorType: EEnergySourceType.WIND_TURBINE,
      powerGenerationValue: 0.08,
      energyGenerationValue: 0.12,
    },
    {
      powerGeneratorType: EEnergySourceType.GENERATOR,
      powerGenerationValue: 2.04,
      energyGenerationValue: 4.2,
    },
    {
      powerGeneratorType: EEnergySourceType.BATTERY,
      powerGenerationValue: 80,
      energyGenerationValue: 5,
      onClick: () => {
        navigate(AppRoute.BATTERY);
      },
    },
  ];

  return (
    <>
      <div className="container-white px-4">
        <Row className="align-items-center">
          <Col>
            <Row>
              <Col className="text-dark font-size-14 font-weight-500">
                Total Generation for&nbsp;
                {moment(
                  generationFilterStore.selectedTotalGenerationDate
                ).format("MMMM")}
              </Col>
            </Row>
            <Row className="mt-3 gap-3 gap-lg-4">
              {Object.keys(totalEnergyGeneration).length === 0 ? (
                <div className="text-light font-size-12 font-weight-400">
                  No total generation of this month
                </div>
              ) : (
                <>
                  {Object.entries(totalEnergyGeneration)
                    .reverse()
                    .map((item, index) => {
                      return (
                        <Col key={index} className="col-auto">
                          <EnergySourceInfo
                            title={findEnergySourceType(
                              item[0] as EEnergySourceType
                            )}
                            value={Number(
                              Helper.roundTo2(
                                powerConvertor(
                                  item[1],
                                  EPowerMeasureType.KILO_WATT
                                )
                              )
                            )}
                            icon={energySourceIconType(
                              item[0] as EEnergySourceType
                            )}
                            isFetching={false}
                          />
                        </Col>
                      );
                    })}
                </>
              )}
            </Row>
          </Col>
          <Col className="col-auto">
            <Row className="align-items-center">
              <Col className="text-primary font-size-12 font-weight-500">
                {moment(
                  generationFilterStore.selectedTotalGenerationDate
                ).format("MMMM")}{" "}
                {new Date(
                  generationFilterStore.selectedTotalGenerationDate
                ).getFullYear()}
              </Col>
              <Col className="col-auto">
                <ButtonWithBadge
                  text="Filter"
                  icon="filter_alt"
                  onClick={() => setShowFilter(true)}
                  badgeValue={0}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row className="align-items-center mt-4 mb-2">
        <Col className="col-auto pe-0">
          <ActiveInactiveIndicator isLive={true} />
        </Col>
        <Col className="text-dark font-weight-500 font-size-14">
          Live Generation
        </Col>
      </Row>
      <Row className="gy-4">
        {cards.map((g, index) => {
          return (
            <Col
              key={index}
              className="col-12 col-sm-6 col-md-12 col-lg-6 col-xxl"
            >
              <GenerationInfoCard
                powerGeneratorType={g.powerGeneratorType}
                powerGenerationValue={g.powerGenerationValue}
                energyGenerationValue={g.energyGenerationValue}
                onClick={g.onClick}
              />
            </Col>
          );
        })}
      </Row>
      <div className="container-white position-relative mt-4">
        <Row className="justify-content-end">
          <Col className="col-auto">
            <AppSelect
              selectedValue={{ ...selectedUnitType }}
              options={[...unitTypes]}
              onChangeOption={(selectedOption) => {
                setSelectedUnitType(selectedOption);
              }}
              id="unit-type"
            />
          </Col>
        </Row>
        <AreaChart
          borderColor1="#2DEC9F"
          borderColor2="#2EB5ED"
          borderColor3="#7C0F0F"
          backgroundColor1="#29CC3917"
          backgroundColor2="#DFFDFB4D"
          backgroundColor3="#F8F8F8"
          label1="Solar Power"
          label2="Wind Power"
          label3="Generator"
          yAxesUnit={selectedUnitType.value === EUnitType.POWER ? `kW` : `kWh`}
          labels={chartData.labels}
          data1={chartData.solarData}
          data2={chartData.windTurbineData}
          data3={chartData.generatorData}
          hasThirdDataSet={true}
          isChartDataAvailable={true}
          xAxisLabel="Hours"
        />
        <Row className="mt-3">
          <Col className="text-light text-center font-size-12 font-weight-500">
            {selectedUnitType.value === EUnitType.POWER ? `Power` : `Energy`}{" "}
            Generation
          </Col>
        </Row>
        <SpinnerModal show={false} />
      </div>
      <GenerationFilterSidePanel
        showFilter={showFilter}
        setShowFilter={(value: boolean) => {
          setShowFilter(value);
        }}
        onFilter={onFilter}
        selectedTotalGenerationDate={
          generationFilterStore.selectedTotalGenerationDate
        }
        onClearAll={onClearAll}
      />
    </>
  );
};

export default Generation;
