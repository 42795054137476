import { EEnergySourceType } from "../enums/energy-source-type";

export const findEnergySourceType = (sourceType: EEnergySourceType) => {
  switch (sourceType) {
    case EEnergySourceType.SOLAR_PV:
      return "Solar";
    case EEnergySourceType.WIND_TURBINE:
      return "Wind Turbine";
    case EEnergySourceType.BATTERY:
      return "Battery";
    case EEnergySourceType.GENERATOR:
      return "Generator";
    case EEnergySourceType.GRID:
      return "Grid";
  }
};
