import styles from "./tank.module.scss";

interface IProps {
  value: number;
}

const Tank = (props: IProps) => {
  return (
    <div className={styles.tank}>
      <div className={styles.wave} style={{ height: `${props.value}%` }}></div>
    </div>
  );
};

export default Tank;
