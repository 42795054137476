import { Col, Row } from "react-bootstrap";
import styles from "./unplanned-life-line-power-timeline.module.scss";

interface IProps {
  date: string;
  value: number;
  isFirst?: boolean;
  isLast?: boolean;
}

const UnplannedLifeLinePowerTimeline = (props: IProps) => {
  const { date, value, isFirst, isLast } = props;

  return (
    <>
      <Row className="align-items-center">
        <Col className="col-auto">
          <div
            className={`${
              isFirst
                ? styles.indicatorFirst
                : isLast
                ? styles.indicatorLast
                : styles.indicator
            } ${value < 0 ? "bg-red" : "bg-green-3"} rounded-circle`}
          ></div>
        </Col>
        <Col className="col-auto font-size-12 text-light">{date}</Col>
        <Col className="bg-white rounded-4 py-3">
          <Row className="align-items-center">
            <Col
              className={`font-size-12 font-weight-500 ${
                value < 0 ? "text-red" : "text-green-3"
              }`}
              xs={7}
              sm={4}
              lg={3}
              xl={2}
            >
              {value} kWh
            </Col>
            <Col className="text-light font-size-12 ">
              {value < 0 ? "Used" : "Renewed"}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UnplannedLifeLinePowerTimeline;
