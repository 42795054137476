import { Col, Row } from "react-bootstrap";
import ActiveInactiveIndicator from "../../ui-elements/active-inactive-indicator/active-inactive-indicator";
import Gauge from "../../ui-elements/gauge/gauge";
import styles from "./gauge-info-card.module.scss";

interface IProps {
  value: number;
  capacity: number;
}

const GaugeInfoCard = (props: IProps) => {
  const { value, capacity } = props;

  return (
    <div className={`${styles.infoCardContainer} p-4`}>
      <Row className="align-items-center">
        <Col className="col-auto pe-0">
          <ActiveInactiveIndicator isLive={true} />
        </Col>
        <Col className="text-dark font-size-16 font-weight-500 ps-2">
          Live Generation
        </Col>
      </Row>
      <Row>
        <Col className="text-light font-size-12 font-weight-400">
          Capacity: {capacity}kW
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="d-flex justify-content-center">
          <Gauge icon="solar_power" value={value / 1000} maxValue={capacity} />
        </Col>
      </Row>
    </div>
  );
};

export default GaugeInfoCard;
