import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MaterialIcon, {
  MtIcon,
} from "../../ui-elements/material-icon/material-icon";
import styles from "./progress-bar.module.scss";

export interface IProgressBar {
  text: string;
  percentage: number;
  icon: MtIcon;
  containerColor: string;
  progressBarColor: string;
  iconColor: string;
}

const ProgressBar = (props: IProgressBar) => {
  const [percentageProgress, setPercentageProgress] = useState(0);

  const {
    text,
    percentage,
    icon,
    containerColor,
    progressBarColor,
    iconColor,
  } = props;

  useEffect(() => {
    if (percentageProgress < percentage) {
      const interval = setInterval(() => {
        setPercentageProgress((prev) => Math.min(prev + 1, percentage));
      }, 20);

      return () => clearInterval(interval);
    } else {
      setPercentageProgress(percentage);
    }
  }, [percentage, percentageProgress]);

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col className="col-auto">
          <MaterialIcon icon={icon} color={iconColor} />
        </Col>
        <Col>
          <Row>
            <Col className="text-light font-size-12">{text}</Col>
          </Row>
          <Row>
            <Col>
              <div
                style={{ backgroundColor: containerColor }}
                className={`${styles.progressBarContainer} rounded`}
              >
                {percentageProgress ? (
                  <div
                    style={{
                      width: `${percentageProgress}%`,
                      backgroundColor: progressBarColor,
                    }}
                    className={`${styles.progressBar} rounded font-weight-600 font-size-16 text-dark d-flex align-items-center px-2`}
                  >
                    {percentageProgress}%
                  </div>
                ) : (
                  <div
                    className={`${styles.progressBar} rounded font-weight-600 font-size-16 text-dark d-flex align-items-center px-2`}
                  >
                    {percentageProgress}{" "}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ProgressBar;
