import { useEffect, useState } from "react";
import FilteringSidePanel from "../../../../../shared/oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import AppDatePicker from "../../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";

interface IProps {
  showFilter: boolean;
  setShowFilter: (value: boolean) => void;
  onFilter: (selectedTotalGenerationDate: Date) => void;
  selectedTotalGenerationDate: number;
  onClearAll: () => void;
}

const GenerationFilterSidePanel = (props: IProps) => {
  const { showFilter, setShowFilter, onFilter, onClearAll } = props;

  const [selectedTotalGenerationDate, setSelectedTotalGenerationDate] =
    useState(new Date());

  useEffect(() => {
    setSelectedTotalGenerationDate(new Date(props.selectedTotalGenerationDate));
  }, [props.selectedTotalGenerationDate]);

  return (
    <FilteringSidePanel
      isOpen={showFilter}
      onClose={() => {
        setShowFilter(false);
      }}
      onFilter={() => {
        onFilter(selectedTotalGenerationDate);
      }}
      onClearAll={onClearAll}
    >
      <>
        <AppDatePicker
          selectedDate={selectedTotalGenerationDate}
          onChange={(date: Date) => {
            setSelectedTotalGenerationDate(date);
          }}
          monthYearPicker={true}
          dateFormat={"MMMM-yyyy"}
          fontSize={12}
          maxDate={
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
          }
        />
      </>
    </FilteringSidePanel>
  );
};

export default GenerationFilterSidePanel;
